import React, {useState} from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {HeaderTabs} from './HeaderTabs'
import {ViewTransactionsTable} from './ViewTransactionsTable'

const ViewTransactions: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('')

  return (
    <Routes>
      <Route
        element={
          <>
            <HeaderTabs setActiveTab={setActiveTab} />
            <ViewTransactionsTable activeTab={activeTab} />
            <Outlet />
          </>
        }
      >
        <Route path='journal' element={<></>} />
        <Route path='receipt' element={<></>} />
        <Route path='payment' element={<></>} />
        <Route path='contra' element={<></>} />
        <Route path='sales' element={<></>} />
        <Route path='purchase' element={<></>} />
        <Route path='debitnote' element={<></>} />
        <Route path='creditnote' element={<></>} />
        <Route index element={<Navigate to='/transactions/view/journal' />} />
      </Route>
    </Routes>
  )
}

export default ViewTransactions
