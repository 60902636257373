import React, {FC, useEffect, useMemo, useState} from 'react'
import {Field, ErrorMessage, useFormikContext} from 'formik'
import {getAllCurrencyOptions} from '../../../product-master/currency/api'

const Step3: FC = () => {
  const {values}: any = useFormikContext()
  const [currencyOptions, setCurrencyOptions] = useState([])
  useEffect(() => {
    async function fetchCurrency() {
      const res: any = await getAllCurrencyOptions()
      console.log(res)
      res && setCurrencyOptions(res)
    }
    fetchCurrency()
  }, [])
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-gray-900'>
          {values.accountType === 'personal' ? 'Business' : 'Company'} Details
        </h2>

        <div className='text-gray-500 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Company Name</label>

        <Field name='companyName' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='companyName' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required'>Company Address</label>

        <Field name='companyAddress' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='companyAddress' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required'>State</label>

        <Field name='addressState' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='addressState' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required'>Country</label>

        <Field name='addressCountry' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='addressCountry' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required'>Zip Code</label>

        <Field name='addressZipcode' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='addressZipcode' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required'>Phone Number</label>

        <Field
          name='companyTelephone'
          className='form-control form-control-lg form-control-solid'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='companyTelephone' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required'>Email</label>

        <Field name='companyEmail' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='companyEmail' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required'>Company Website</label>

        <Field name='companyWebsite' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='companyWebsite' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required'>GST Number</label>

        <Field name='gstNumber' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='gstNumber' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required'>CIN</label>

        <Field name='cin' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='cin' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required'>Reporting Currency</label>

        <Field
          name='reportingCurrency'
          className='form-control form-control-lg form-control-solid'
          as='select'
        >
          <option value=''>Select a Currency</option>
          {currencyOptions.map((currency: any, i) => (
            <option value={currency?.currency_code} key={i}>
              {currency?.currency_code}
            </option>
          ))}
        </Field>
        <div className='text-danger mt-2'>
          <ErrorMessage name='reportingCurrency' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required'>Incorporation Date</label>

        <Field
          type='date'
          name='incorporationDate'
          className='form-control form-control-lg form-control-solid'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='incorporationDate' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required'>Financial Year Start</label>

        <Field
          type='date'
          name='fyStartDate'
          className='form-control form-control-lg form-control-solid'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='fyStartDate' />
        </div>
      </div>
    </div>
  )
}

export {Step3}
