import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL

export interface ProductLedger {
  dbId?: number
  ledgerCode: string
  parentLedger: string
  ledger: string
  parentGroup: string
  subGroup: null | string
  currentBalance: string
  transactionType: string
  account_number: string | null
  address: string | null
  branch_name: string | null
  country: string | null
  ifsc_code: string | null
  zipcode: string | null
  subLedger: string | null
  state: string | null
  personName: string | null
  isParentLedger: boolean
  openingBalance: number
  currency?: string
}

export const getProductLedgers = async (
  companyId: string
): Promise<ProductLedger[] | undefined> => {
  return await axios
    .get(`${API_URL}/product-master/ledger/${companyId}`)
    .then((response: AxiosResponse<ProductLedger[]>) => response.data)
    .then((response: ProductLedger[]) => response)
}

export interface CreateProductLedgerBody {
  dbId?: number
  companyId: string
  subLedgerName: string | null
  isSubLedger: boolean
  parentLedgerName: string
  parentGroupName: string
  subGroupName: string | null
  email?: string
  personName?: string
  address?: string
  state?: string
  country?: string
  zipcode?: string
  accountNumber?: string
  branchName?: string
  ifscCode?: string
  ledgerCode: string
  openingBalance: number
  balanceType: string
  asOnDate: string
  previousParentLedgerName?: string
  previousSubLedgerName?: string
}

export const createProductLedger = async (
  body: CreateProductLedgerBody
): Promise<{detail: string} | undefined> => {
  try {
    const response = await axios.post(`${API_URL}/product-master/ledger/save`, body)
    return response.data
  } catch (error: any) {
    if (error.response) {
      console.log(error)
      throw error.response.data
    }
  }
}

export const deleteProductLedger = async (
  companyId: string
): Promise<{detail: string} | undefined> => {
  return await axios
    .delete(`${API_URL}/product-master/ledger/${companyId}`)
    .then((response: AxiosResponse<{detail: string}>) => response.data)
    .then((response: {detail: string}) => response)
}

export interface LedgerGroupMappingResp {
  ledgerMapping: LedgerMapping
  groupMapping: GroupMapping
  startDate: string
}

export interface GroupMapping {
  [key: string]: string[]
}

export interface LedgerMapping {
  [key: string]: LedgerDetails
}

export interface LedgerDetails {
  parentGroup: string
  subGroup: null | string
}

export const getLedgerGroupMapping = async (
  companyId: string,
  financialYear: string
): Promise<LedgerGroupMappingResp | undefined> => {
  return await axios
    .get(`${API_URL}/product-master/data/create-ledger/${companyId}?fy_name=${financialYear}`)
    .then((response: AxiosResponse<LedgerGroupMappingResp>) => response.data)
    .then((response: LedgerGroupMappingResp) => response)
}

export const downloadPdf = async (
  records: ProductLedger[],
  companyId: string,
  fyYear: string
): Promise<any | undefined> => {
  return await axios.post<any>(
    `${API_URL}/product-master/download/ledger`,
    {
      records,
      companyId,
      fyYear,
    },
    {
      responseType: 'arraybuffer',
    }
  )
}
