/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl, KTIcon} from '../../../_metronic/helpers'
import {Form, Formik, FormikValues} from 'formik'
import * as Yup from 'yup'
import {Step1} from './components/steps/Step1'
import {Step2} from './components/steps/Step2'
import {Step3} from './components/steps/Step3'
import {Step4} from './components/steps/Step4'
import {Step5} from './components/steps/Step5'
import {StepperComponent} from '../../../_metronic/assets/ts/components'
import {useAuth} from './core/Auth'

const createAccountSchemas = [
  Yup.object({
    accountType: Yup.string().required().label('Account Type'),
  }),
  Yup.object({
    accountName: Yup.string().required().label('Account Name'),
  }),
  Yup.object({
    companyEmail: Yup.string()
      .email('Invalid email')
      .matches(/@[^.]*\./, 'Invalid email')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is a required field')
      .label('Email'),
    companyName: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Company Name is a required field')
      .label('Company Name'),
    companyAddress: Yup.string().required('Address is a required field').label('Company Address'),
    addressState: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('State is a required field')
      .label('State'),
    addressCountry: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Country is a required field')
      .label('Country'),
    addressZipcode: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .required('Zip Code is a required field')
      .label('Zip Code'),

    companyTelephone: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'Invalid Phone number'
      )
      .required('Phone Number is a required field')
      .label('Phone Number'),

    cin: Yup.string().required('CIN is a required field').label('CIN'),
    incorporationDate: Yup.string()
      .required('Incorporation Date is a required field')
      .label('Incorporation Date'),
    fyStartDate: Yup.string()
      .required('Financial Year start is a required field')
      .label('Financial Year Start'),
    reportingCurrency: Yup.string()
      .required('Reporting Currency is a required field')
      .label('Reporting Currency'),

    website: Yup.string().label('Company Website'),
    gstNumber: Yup.string().label('GST Number'),
  }),
  Yup.object({
    name: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('First name is required')
      .label('Username'),
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required')
      .label('Email'),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required')
      .label('Password'),
    confirmPassword: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password confirmation is required')
      .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match")
      .label('Confirm Password'),
  }),
]

const inits = {
  accountType: 'personal',
  accountTeamSize: '50+',
  accountName: '',
  accountPlan: '1',
  companyName: '',
  incorporationDate: '',
  fyStartDate: '',
  reportingCurrency: '',
  companyAddress: '',
  addressState: '',
  addressCountry: '',
  addressZipcode: '',
  companyTelephone: '',
  companyEmail: '',
  companyWebsite: '',
  cin: '',
  gstNumber: '',
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
}
const initialValues = {
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  acceptTerms: false,
  role: '',
  region: '',
  address: '',
  countryCode: '',
  mobile: '',
  isSuperAdmin: false,
}
const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  role: Yup.string().oneOf(['user', 'admin']).required('Role is required'),
  region: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Region is required'),
  address: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Address is required'),
  countryCode: Yup.string().required('Country Code is required'),
  mobile: Yup.string()
    .min(6, 'Minimum 6 digits')
    .max(10, 'Maximum 10 digits')
    .required('Mobile number is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})
const SignupWizard = () => {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [stepper, setStepper] = useState<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const loadStepper = () => {
    setStepper(StepperComponent.createInsance(stepperRef.current as HTMLDivElement))
  }
  console.log(stepper)
  const prevStep = () => {
    if (!stepper) {
      return
    }

    stepper.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.currentStepIndex - 1])
  }

  const submitStep = (values: any, actions: FormikValues) => {
    if (!stepper) {
      return
    }

    if (stepper.currentStepIndex !== stepper.totalStepsNumber) {
      stepper.goNext()
    } else {
      stepper.goto(1)
      actions.resetForm()
    }

    console.log(values)

    setCurrentSchema(createAccountSchemas[stepper.currentStepIndex - 1])
  }
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])
  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])
  console.log(stepper?.currentStepIndex)
  return (
    <div className='d-flex flex-column flex-lg-row-reverse flex-column-fluid h-100'>
      <div
        ref={stepperRef}
        className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row-reverse flex-row-fluid overflow-hidden'
        id='kt_create_account_stepper'
      >
        {/* begin::Body */}
        <div className='d-flex flex-column flex-lg-row-fluid w-lg-75 p-10 order-2 order-lg-1 overflow-auto'>
          {/* begin::Form */}
          <div className='d-flex flex-center flex-column-fluid flex-lg-row-fluid'>
            {/* begin::Wrapper */}
            <div className=' p-10'>
              <Formik validationSchema={currentSchema} initialValues={inits} onSubmit={submitStep}>
                {() => (
                  <Form
                    className='w-100 w-xl-700px px-9'
                    noValidate
                    id='kt_create_account_form'
                    placeholder={undefined}
                  >
                    <div className='current' data-kt-stepper-element='content'>
                      <Step1 />
                    </div>

                    <div data-kt-stepper-element='content'>
                      <Step2 />
                    </div>

                    <div data-kt-stepper-element='content'>
                      <Step3 />
                    </div>

                    <div data-kt-stepper-element='content'>
                      <Step4 />
                    </div>

                    <div data-kt-stepper-element='content'>
                      <Step5 />
                    </div>

                    <div className='d-flex flex-stack pt-10'>
                      <div className='mr-2'>
                        <button
                          onClick={prevStep}
                          type='button'
                          className='btn btn-lg btn-light-primary me-3'
                          data-kt-stepper-action='previous'
                        >
                          <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                          Back
                        </button>
                      </div>

                      <div>
                        <button type='submit' className='btn btn-lg btn-primary me-3'>
                          <span className='indicator-label'>
                            {stepper?.currentStepIndex && stepper.currentStepIndex < 4
                              ? 'Continue'
                              : stepper?.currentStepIndex === 4
                              ? 'Submit'
                              : 'Sign in'}

                            <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Form */}

          {/* begin::Footer */}
          <div className='d-flex flex-center flex-wrap px-5'>
            {/* begin::Links */}
            <div className='d-flex fw-semibold text-primary fs-base'>
              <a href='#' className='px-5' target='_blank'>
                Terms
              </a>

              <a href='#' className='px-5' target='_blank'>
                Plans
              </a>

              <a href='#' className='px-5' target='_blank'>
                Contact Us
              </a>
            </div>
            {/* end::Links */}
          </div>
          {/* end::Footer */}
        </div>
        {/* end::Body */}

        {/* begin::Aside */}
        <div
          className='d-flex flex-lg-row-fluid bgi-size-cover bgi-position-center order-1 order-lg-2'
          style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
        >
          {/* begin::Content */}
          <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
            {/* begin::Logo */}
            <Link to='/' className='mb-12'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/custom-1.png')} className='h-75px' />
            </Link>
            {/* end::Logo */}
            <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9 bg-transparent'>
              {/* begin::Wrapper*/}
              <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
                {/* begin::Nav*/}
                <div className='stepper-nav'>
                  {/* begin::Step 1*/}
                  <div className='stepper-item current' data-kt-stepper-element='nav'>
                    {/* begin::Wrapper*/}
                    <div className='stepper-wrapper'>
                      {/* begin::Icon*/}
                      <div className='stepper-icon w-40px h-40px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>1</span>
                      </div>
                      {/* end::Icon*/}

                      {/* begin::Label*/}
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>Account Type</h3>

                        <div className='stepper-desc fw-semibold'>Setup Your Account Details</div>
                      </div>
                      {/* end::Label*/}
                    </div>
                    {/* end::Wrapper*/}

                    {/* begin::Line*/}
                    <div className='stepper-line h-40px'></div>
                    {/* end::Line*/}
                  </div>
                  {/* end::Step 1*/}

                  {/* begin::Step 2*/}
                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    {/* begin::Wrapper*/}
                    <div className='stepper-wrapper'>
                      {/* begin::Icon*/}
                      <div className='stepper-icon w-40px h-40px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>2</span>
                      </div>
                      {/* end::Icon*/}

                      {/* begin::Label*/}
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>Account Settings</h3>
                        <div className='stepper-desc fw-semibold'>Setup Your Account Settings</div>
                      </div>
                      {/* end::Label*/}
                    </div>
                    {/* end::Wrapper*/}

                    {/* begin::Line*/}
                    <div className='stepper-line h-40px'></div>
                    {/* end::Line*/}
                  </div>
                  {/* end::Step 2*/}

                  {/* begin::Step 3*/}
                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    {/* begin::Wrapper*/}
                    <div className='stepper-wrapper'>
                      {/* begin::Icon*/}
                      <div className='stepper-icon w-40px h-40px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>3</span>
                      </div>
                      {/* end::Icon*/}

                      {/* begin::Label*/}
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>Information</h3>
                        <div className='stepper-desc fw-semibold'>Your Business Related Info</div>
                      </div>
                      {/* end::Label*/}
                    </div>
                    {/* end::Wrapper*/}

                    {/* begin::Line*/}
                    <div className='stepper-line h-40px'></div>
                    {/* end::Line*/}
                  </div>
                  {/* end::Step 3*/}

                  {/* begin::Step 4*/}
                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    {/* begin::Wrapper*/}
                    <div className='stepper-wrapper'>
                      {/* begin::Icon*/}
                      <div className='stepper-icon w-40px h-40px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>4</span>
                      </div>
                      {/* end::Icon*/}

                      {/* begin::Label*/}
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>Login Details</h3>
                        <div className='stepper-desc fw-semibold'>Set Your Login Credentials</div>
                      </div>
                      {/* end::Label*/}
                    </div>
                    {/* end::Wrapper*/}

                    {/* begin::Line*/}
                    <div className='stepper-line h-40px'></div>
                    {/* end::Line*/}
                  </div>
                  {/* end::Step 4*/}

                  {/* begin::Step 5*/}
                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    {/* begin::Wrapper*/}
                    <div className='stepper-wrapper'>
                      {/* begin::Icon*/}
                      <div className='stepper-icon w-40px h-40px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>5</span>
                      </div>
                      {/* end::Icon*/}

                      {/* begin::Label*/}
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>Completed</h3>
                        <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                      </div>
                      {/* end::Label*/}
                    </div>
                    {/* end::Wrapper*/}
                  </div>
                  {/* end::Step 5*/}
                </div>
                {/* end::Nav*/}
              </div>
              {/* end::Wrapper*/}
            </div>
            {/* begin::Image */}
            {/* <img
            className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'
            src={toAbsoluteUrl('/media/misc/auth-screens.png')}
            alt=''
          /> */}
            {/* end::Image */}

            {/* begin::Title */}
            {/* <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
            Fast, Efficient and Productive
          </h1> */}
            {/* end::Title */}

            {/* begin::Text */}
            {/* <div className='text-white fs-base text-center'>
            In this kind of post,{' '}
            <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
              the blogger
            </a>
            introduces a person they’ve interviewed <br /> and provides some background information
            about
            <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
              the interviewee
            </a>
            and their <br /> work following this is a transcript of the interview.
          </div> */}
            {/* end::Text */}
          </div>
          {/* end::Content */}
        </div>
        {/* end::Aside */}
      </div>
    </div>
  )
}

export {SignupWizard}
