import {useEffect, useMemo, useState} from 'react'
import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import Select from 'react-select'
import Modals from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import './Style.css'
import {KTIcon, formatNumber} from '../../../../_metronic/helpers'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {
  CreateTransactionBody,
  IndividualTransaction,
  SetOffInvoiceResp,
  VoucherTypeMap,
  createNewTransaction,
  createTransaction,
  getAccountingDetails,
  getExchangeRate,
  getSetOffInvoiceDetails,
} from '../API/EnterTransactionsAPI'
import {useLayout} from '../../../../_metronic/layout/core'
import {TransactionRow} from './TransactionRow'
import {toast} from 'react-hot-toast'
import {Modal} from 'react-bootstrap'
import {type Transaction as TransactionType} from '../API/ViewTransactionsAPI'
import {ProductLedger} from '../../product-master/ledger/api'
import {CardsWidget7} from '../../../../_metronic/partials/widgets'
import {flatten, get, isEmpty, map, max, sum, compact, isArray} from 'lodash'
import moment from 'moment'
import Swal from 'sweetalert2'
import Preloader from '../../loaders/Preloader'
import {useLoaderContext} from '../../loaders/LoaderProvider'
import InfoAlert from '../../infoAlert/InfoAlert'
import {CreateEditLedger} from '../../product-master/ledger/CreateEditLedger'
import {Loader} from '../../product-master/loader'
function hasDuplicateTransactionType(arr: any) {
  const seen = new Set()

  for (const obj of arr) {
    const key = `${obj.parentLedger}-${obj.subLedger}-${obj.transactionType}`

    if (seen.has(key)) {
      return true // Duplicate found, exit early
    }

    seen.add(key)
  }

  return false // No duplicates found
}

type OptionType = {
  value: string | null
  label: string
}

export const Transaction: React.FC<{activeTab: keyof VoucherTypeMap}> = ({activeTab}) => {
  const {loading, setLoading, error, setError} = useLoaderContext()

  const navigate = useNavigate()
  const location = useLocation()
  const {company, financialYear} = useLayout()
  const [errorMessage, setErrorMessage] = useState('')
  const [dbId, setDbId] = useState<number>()
  const [currencyModal, setCurrencyModal] = useState(false)
  const [setOffModal, setSetOffModal] = useState(false)
  const [previousPath, setPreviousPath] = useState('')
  const [voucherName, setVoucherName] = useState<OptionType>()
  const [voucherNumber, setVoucherNumber] = useState<number>()
  const [previousVoucherType, setPreviousVoucherType] = useState<string>()
  const [currency, setCurrency] = useState<OptionType>()
  const [isValidDate, setIsValidDate] = useState(true)
  const [voucherDate, setVoucherDate] = useState(new Date().toISOString().split('T')[0])
  const initialData: IndividualTransaction[] = [
    {
      transactionType: 'debit',
      parentLedger: '',
      subLedger: null,
      amount: 0,
      transactionAmount: 0,
      ledgerCode: '',
    },
    {
      transactionType: 'credit',
      parentLedger: '',
      subLedger: null,
      amount: 0,
      transactionAmount: 0,
      ledgerCode: '',
    },
  ]
  const [data, setData] = useState<IndividualTransaction[]>(initialData)
  const [nextDisabled, setNextDisabled] = useState(true)
  const [createLedger, setCreateLedger] = useState(false)
  const [narration, setNarration] = useState('')
  const [reportingCurrency, setReportingCurrency] = useState('')
  const [invoiceNumber, setInvoiceNumber] = useState<string>()
  const [invoiceDescription, setInvoiceDescription] = useState<string>()

  const [voucherOptions, setVoucherOptions] = useState<OptionType[]>([])
  const [byLedgerDetails, setByLedgerDetails] = useState<Record<string, string[]>>()
  const [toLedgerDetails, setToLedgerDetails] = useState<Record<string, string[]>>()
  const [ledgerCurrentBalanceMapping, setLedgerCurrentBalanceMapping] =
    useState<Record<string, Record<string, string>>>()
  const [currencyOptions, setCurrencyOptions] = useState<OptionType[]>([])
  const [exchangeRate, setExchangeRate] = useState<{
    exchangeRate: number
    asOnDate: string
  }>()
  const [invoiceDetails, setInvoiceDetails] = useState<SetOffInvoiceResp[]>()
  const [editLedger, setEditLedger] = useState<ProductLedger | undefined>(undefined)
  const [applicableRate, setApplicableRate] = useState(0)
  const [updateExchangeRate, setUpdateExchangeRate] = useState(false)
  const [setOffDone, setSetOffDone] = useState(false)
  const [setOffTotalAmount, setSetOffTotalAmount] = useState(0)
  const [status, setStatus] = useState(false)
  const [modal, setModal] = useState(false)

  useEffect(() => {
    if (status) {
      setModal(true)
    }
  }, [location])

  useEffect(() => {
    if (!!location.state) {
      const state = location.state as {transaction: any}
      console.log(state)
      const transaction = state?.transaction
      setDbId(transaction?.dbId)
      setPreviousVoucherType(transaction.voucherType)
      setCurrency({
        value: transaction?.transactingCurrency,
        label: transaction?.transactingCurrency,
      })
      !!transaction?.voucherName &&
        setVoucherName({value: transaction?.voucherName, label: transaction?.voucherName})
      setVoucherDate(transaction?.voucherDate)
      setVoucherNumber(transaction?.voucherNumber)
      setInvoiceDetails(transaction?.invoiceDetails)
      // setInvoiceNumber(transaction?.invoiceNumber.toString())
      // setInvoiceDescription(transaction?.invoiceDescription)
      const transactionDetails: IndividualTransaction[] = transaction.transactionDetails.map(
        (t: any) => {
          return {
            parentLedger: t.parentLedger,
            subLedger: t.subLedger,
            amount: t.transactionAmount / transaction.exchangeMultiplier,
            transactionType: t.transactionType,
          }
        }
      )
      setData(transactionDetails)
      setNarration(transaction.narration || '')
      setExchangeRate({
        exchangeRate: transaction.exchangeMultiplier,
        asOnDate: transaction.voucherDate,
      })
      let otherVouchersData
      if (transaction.voucherType !== 'sales' && transaction.voucherType !== 'purchase') {
        otherVouchersData = transaction.transactionDetails.map((tr: any, i: any) => {
          return {
            advanceDetails: transaction.advanceDetails
              ?.filter((adv: any) => adv.parent_ledger === tr.parentLedger && i > 0)
              .map((adv: any) => {
                if (adv)
                  return {
                    advanceNumber: adv.advance_number,
                    amount: adv.advance_amount,
                    dateOfAdvance: adv.advance_date,
                    remarks: adv.advance_description,
                    transactionType: adv.transaction_type,
                  }
                return undefined
              })
              .map((el: any) => el)[0],

            againstInvoiceDetails: transaction?.againstInvoiceDetails
              ?.filter((inv: any) => inv.parent_ledger === tr.parentLedger)
              .map((_: any, i: number) => {
                return {
                  invoiceAmount: _.invoice_amount,
                  invoiceDate: _.invoice_date,
                  invoiceDueDate: _.invoice_due_date,
                  invoiceNumber: _.invoice_number,
                  remarks: _.remarks,
                  settOffAmount: _.settOffAmount || 0,
                  settledInvoiceAmount: _.settled_amount,
                  transactionId: _.transaction_id,
                  transactionType: _.transaction_type,
                  voucherNumber: _.voucher_number,
                  voucherType: _.voucher_type,
                }
              }),
            message: [],
            nextAdvanceNumber: null,
            referenceAmount: i === 0 ? 0 : tr.transactionAmount,
            setOffInvoiceParentLedger: tr.parentLedger,
            setOffInvoiceSubLedger: null,
            showOptions: i === 0 ? false : true,
            transactionType: i === 0 ? null : tr.transactionType,
          }
        })
      }
      setTransactionDetails({
        data: otherVouchersData,
        transactionDetails: {
          companyId: company.value,
          exchangeMultiplier: transaction.exchangeMultiplier,
          fyName: financialYear.value,
          narration: '',
          reportingCurrency: transaction.reportingCurrency,
          totalAmount: transaction.totalAmount,
          transactingCurrency: transaction.transactingCurrency,
          transactionDetails: transactionDetails,
          voucherDate: transaction.voucherDate,
          voucherName: transaction.voucherName,
          voucherNumber: transaction.voucherNumber,
          voucherType: transaction.voucherType,
        },
      })

      setStatus(true)
    }
  }, [location.state])

  useEffect(() => {
    if (!location.state) {
      setInvoiceDetails([])
    }
  }, [location.state, activeTab])

  useEffect(() => {
    if (currency?.value && reportingCurrency && currency?.value !== reportingCurrency) {
      const getExchangeRates = async () => {
        if (company?.value && financialYear?.value) {
          const res = await getExchangeRate(
            company.value,
            financialYear.value,
            voucherDate,
            currency?.value || ''
          )
          res && setExchangeRate({exchangeRate: res?.exchangeRate, asOnDate: res?.asOnDate})
          res && setApplicableRate(res?.exchangeRate)
        }
      }

      getExchangeRates()
      setCurrencyModal(true)
    }
  }, [company?.value, currency, financialYear?.value, reportingCurrency, voucherDate])

  useEffect(() => {
    if (company?.value && financialYear?.value && !createLedger) {
      const fetchAccountingDetails = async () => {
        setError(false)
        setLoading(true)
        try {
          const res = await getAccountingDetails(company?.value, financialYear?.value)
          if (!res)
            throw new Error(
              'Loading ledger data encountered an error. Please try refreshing the page.'
            )
          if (res?.voucherTypeMap[activeTab]) {
            const voucherOption = res?.voucherTypeMap[activeTab].map((opt) => ({
              label: opt,
              value: opt === 'None' ? null : opt,
            }))
            setVoucherOptions(voucherOption)
          }

          res?.byLedger[activeTab === 'purchase' ? 'purchases' : activeTab] &&
            setByLedgerDetails(res?.byLedger[activeTab === 'purchase' ? 'purchases' : activeTab])

          res?.toLedger[activeTab === 'purchase' ? 'purchases' : activeTab] &&
            setToLedgerDetails(res?.toLedger[activeTab === 'purchase' ? 'purchases' : activeTab])

          res?.ledgerCurrentBalanceMapping &&
            setLedgerCurrentBalanceMapping(res?.ledgerCurrentBalanceMapping)

          if (res?.voucherCurrency) {
            const currencyOption = res?.voucherCurrency.map((opt) => ({label: opt, value: opt}))
            setCurrencyOptions(currencyOption)
          }
          if (res?.reportingCurrency) {
            setReportingCurrency(res?.reportingCurrency)
            !location.state &&
              setCurrency({label: res?.reportingCurrency, value: res?.reportingCurrency})
          }
        } catch (err: any) {
          err &&
            toast.error('Loading ledger data encountered an error. Please try refreshing the page.')
          setError(true)
        } finally {
          setLoading(false)
          // setCompleted(false)
        }
      }
      fetchAccountingDetails()
    }
  }, [
    activeTab,
    company?.value,
    financialYear?.value,
    location.state,
    setLoading,
    setError,
    createLedger,
  ])

  const {byTotal: debitTotal, toTotal: creditTotal} = useMemo(() => {
    const byTotal = data.reduce((acc, val) => {
      acc = acc + (val.transactionType === 'debit' ? val.amount : 0)
      return acc
    }, 0)
    const toTotal = data.reduce((acc, val) => {
      acc = acc + (val.transactionType === 'credit' ? val.amount : 0)
      return acc
    }, 0)

    return {byTotal, toTotal}
  }, [data])

  const handleCreateTransaction = async () => {
    if (company?.value && financialYear?.value && currency?.value) {
      setLoading(true)

      const body: any = {
        voucherType: activeTab,
        voucherNumber: 0,
        companyId: company?.value,
        fyName: financialYear?.value,
        voucherName: voucherName?.value == null ? '' : voucherName?.value,
        voucherDate: voucherDate,
        transactingCurrency: currency?.value,
        totalAmount: 1.0,
        reportingCurrency: reportingCurrency,
        transactionDetails: data,
        narration: narration,
        exchangeMultiplier: '0',
        referenceAmount: transactionDetails?.referenceAmount,
        showOptions: transactionDetails?.showOptions,
        nextInvoiceNumber: transactionDetails?.nextInvoiceNumber,
        transactionType: transactionDetails?.transactionType,
        message: transactionDetails?.message,
        invoiceDetails: transactionDetails?.invoiceDetails,
        againstAdvanceDetails: transactionDetails?.againstAdvanceDetails,
        data: transactionDetails?.data,
      }

      const validAgainstAdvanceDetails = transactionDetails?.againstAdvanceDetails || []
      const validAgainstInvoiceDetails = transactionDetails?.againstInvoiceDetails || []

      const updateAgainstAdvanceDetails = validAgainstAdvanceDetails.map((item: any) => {
        return {
          ...item,
          transactionId: transactionDetails?.transactionId || '',
        }
      })

      const updateAgainstInvoiceDetails = validAgainstInvoiceDetails.map((item: any) => {
        return {
          ...item,
          transactionId: transactionDetails?.transactionId || '',
        }
      })

      const validateInvoiceAmount = isArray(invoiceAmount) ? invoiceAmount : [invoiceAmount]
      const invoiceDetails = isEmpty(multiSetOffData)
        ? {
            invoiceNumber:
              transactionDetails?.invoiceNumber || transactionDetails?.nextInvoiceNumber || '',
            invoiceDate: transactionDetails?.transactionDetails?.voucherDate,
            invoiceDueDate: invoiceDueDate,
            amount: validateInvoiceAmount.reduce((acc: number, value: number) => {
              return acc + value
            }, 0),
            transactionType: transactionDetails?.transactionType || '',
            remarks: invoiceRemark,
          }
        : null
      let updatedTransactionDetails = []
      if (!isEmpty(multiSetOffData)) {
        updatedTransactionDetails = [...transactionDetails?.data].map((item: any) => {
          const naReferenceAmount =
            item.referenceAmount -
            (item?.advanceDetails?.amount +
              item?.againstInvoiceDetails?.reduce(
                (acc: any, el: any) => acc + el?.settOffAmount,
                0
              ))
          return {
            ...item,
            naReferenceAmount: naReferenceAmount || 0,
            isNAReferenceAmount: Boolean(naReferenceAmount),
            againstInvoiceDetails: item.againstInvoiceDetails.map((invoice: any) => {
              return {
                ...invoice,
                amount: invoice?.invoiceAmount || 0,
                transactionId: transactionDetails?.transactionId || 0,
              }
            }),
          }
        })
      }
      const tdata = updatedTransactionDetails.filter((item: any) => item?.showOptions)
      // const totalReferenceAmount = tdata.reduce((acc, el) => acc + el.referenceAmount, 0)
      // const totalAdvance = tdata.reduce((acc, el) => acc + el.advanceDetails.amount, 0)
      // const totalInvoiceSetoffAmount = tdata
      //   .map((el) => el.againstInvoiceDetails)
      //   .map((el) => el.reduce((acc: any, inv: any) => acc + inv.settOffAmount, 0))
      //   .reduce((acc, el) => acc + el, 0)

      // const naReferenceAmount = totalReferenceAmount - (totalAdvance + totalInvoiceSetoffAmount)

      // console.log('Total Reference Amount: ', totalReferenceAmount)
      // console.log('Total Advance Amount: ', totalAdvance)
      // console.log('Total Invoice Setoffs: ', totalInvoiceSetoffAmount)
      const newBody: any = {
        companyId: company?.value,
        fyName: financialYear?.value,
        voucherType: activeTab,
        voucherName: voucherName?.value == null ? '' : voucherName?.value,
        voucherNumber: 0,
        // voucherNumber: null,
        transactingCurrency: currency?.value,
        reportingCurrency: reportingCurrency,
        voucherDate: voucherDate,
        totalAmount: 1.0,
        exchangeMultiplier: 1,
        narration: narration || null,
        transactionDetails: data,
        referenceAmount: transactionDetails?.referenceAmount,
        showOptions: transactionDetails?.showOptions,
        nextInvoiceNumber: transactionDetails?.nextInvoiceNumber,
        transactionType: transactionDetails?.transactionType,
        message: transactionDetails?.message,
        invoiceDetails: invoiceDetails,
        setOffAdvanceParentLedger: transactionDetails?.setOffAdvanceParentLedger,
        setOffAdvanceSubLedger: transactionDetails?.setOffAdvanceSubLedger,
        setOffAdvanceLedgerCode: transactionDetails?.setOffAdvanceLedgerCode,
        isNAReferenceAmount: 0,
        isEditTransaction: transactionDetails?.isEditTransaction,
        againstAdvanceDetails: updateAgainstAdvanceDetails,
        againstInvoiceDetails: updateAgainstInvoiceDetails,
        data: tdata,
      }
      console.log(newBody)
      await createTransaction(newBody)
        .then((res) => {
          if (res?.detail) {
            toast.success(res?.detail)
            setVoucherDate(new Date().toISOString().split('T')[0])
            setData(initialData)
            setNarration('')
            setStatus(false)
          }
        })
        .catch((err) => toast.error(err.detail || 'Some error occurred. Please try again.'))
        .finally(() => setLoading(false))
    }
  }
  const handleCreateNewTransaction = async () => {
    if (company?.value && financialYear?.value && currency?.value) {
      setLoading(true)
      const body: CreateTransactionBody = {
        companyId: company?.value,
        fyName: financialYear?.value,
        voucherType: activeTab,
        voucherName: voucherName?.value == null ? '' : voucherName?.value,
        voucherNumber: 0,
        transactingCurrency: currency?.value,
        reportingCurrency: reportingCurrency,
        voucherDate: voucherDate,
        totalAmount: 1.0,
        exchangeMultiplier: '0',
        narration: narration,
        transactionDetails: data,
      }
      await createNewTransaction(body)
        .then((res) => {
          if (res?.transactionDetails) {
            setStatus(true)
            toast.success('Success')
            setTransactionDetails(res)
            setInvoiceDescription('')
            setNarration('')
            setSetOffDone(false)
          }
        })
        .catch((err) => toast.error(err.detail || 'Some error occurred. Please try again.'))
        .finally(() => setLoading(false))
    }
  }
  const handleSetOff = async () => {
    const item = data.find((d) => d.transactionType === 'credit')
    if (company?.value && financialYear?.value) {
      const res = await getSetOffInvoiceDetails(
        company?.value,
        financialYear?.value,
        item?.parentLedger ?? '',
        voucherDate,
        item?.subLedger ? item?.subLedger : undefined
      )
      if (res?.invoiceDetails) {
        const oldInvoices = invoiceDetails ? [...invoiceDetails] : []
        const newInvoices = res.invoiceDetails.map((r) => ({...r, setOffAmount: 0}))
        oldInvoices.map((oi) => {
          const ind = newInvoices.findIndex((ni) => ni.invoiceNumber === oi.invoiceNumber)
          if (ind === -1) newInvoices.push(oi)
          else {
            newInvoices[ind].setOffAmount = oi.setOffAmount
          }
          return null
        })

        setInvoiceDetails(newInvoices)
      }
      setSetOffModal(true)
      setSetOffTotalAmount(item?.amount ?? 0)
    }
  }

  const handleSetOffLogic = () => {
    setSetOffDone(true)
    setSetOffModal(false)
  }
  const [transactionDetails, setTransactionDetails]: any = useState()

  // Add state for Invoice Due Days
  const [invoiceDueDays, setInvoiceDueDays]: any = useState(30)
  const [invoiceDueDate, setInvoiceDueDate]: any = useState('')
  const [invoiceAmount, setInvoiceAmount]: any = useState([])
  const [newInvoiceNumber, setNewInvoiceNumber]: any = useState()
  const [invoiceRemark, setInvoiceRemark]: any = useState('')
  const [againstInvoiceAmount, setAgainstInvoiceAmount]: any = useState([])
  console.log(invoiceAmount)
  console.log(againstInvoiceAmount)
  const handleInvoiceInputChange = (
    e: any,
    index: number,
    field: string,
    indexParent: number,
    type: string
  ) => {
    console.log('Amount  ', e)
    console.log('index  ', index)
    console.log('Field  ', field)
    console.log('indexParent  ', indexParent)
    console.log('type  ', type)
    if (type === 'againstInvoiceDetails') {
      const updatedTransactionDetails: any = [...transactionDetails?.data]
      updatedTransactionDetails[indexParent].againstInvoiceDetails[index][field] = e
      setTransactionDetails({...transactionDetails, data: updatedTransactionDetails})
    } else {
      const updatedTransactionDetails: any = [...transactionDetails]
      updatedTransactionDetails[indexParent].againstAdvanceDetails[index][field] = e
      setTransactionDetails(updatedTransactionDetails)
    }
  }
  useEffect(() => {
    setNewInvoiceNumber(transactionDetails?.nextInvoiceNumber)
  }, [transactionDetails])

  // Set default values when the component initializes
  useEffect(() => {
    const updatedInvoiceDueDate = updateDueDateFromDays(invoiceDueDays)
    const updatedInvoiceDueDate2 = moment().add(invoiceDueDays, 'days').format('YYYY-MM-DD')

    setInvoiceDueDate(updatedInvoiceDueDate)
    // Calculate the difference between the new due date and today to update Invoice Due Days
    // const today: any = new Date()
    // const dueDate: any = transactionDetails?.[1]?.newInvoice?.[0]?.dueDate
    // const newDueDateObject: any = new Date(dueDate)
    // const differenceInDays = Math.floor((newDueDateObject - today) / (24 * 60 * 60 * 1000))
    // // Set default values
    // setInvoiceDueDays(30) // Default Invoice Due Days is set to 7
  }, [voucherDate]) // Empty dependency array ensures this effect runs only once during component mount

  useEffect(() => {
    if (
      data.length < 2 &&
      data.some((el) => el.transactionType === 'credit') &&
      data.some((el) => el.transactionType === 'debit')
    )
      setErrorMessage(
        'Ensure there is at least one debit and one credit line item before proceeding with the transaction'
      )
    else if (data.some((el) => !el.parentLedger || !el.subLedger))
      setErrorMessage(
        'Before proceeding with the transaction, please ensure that you have selected the appropriate ledgers'
      )
    else if (creditTotal !== debitTotal) {
      setErrorMessage(
        'Before proceeding with the transaction, ensure that the total debit equals the total credit'
      )
    } else setErrorMessage('')

    const checkDuplicate = hasDuplicateTransactionType(data)
    setNextDisabled(checkDuplicate)
  }, [data, creditTotal, debitTotal])

  const handleDueDaysChange = (newDueDays: any) => {
    const updatedInvoiceDueDate = moment(voucherDate).add(newDueDays, 'days').format('YYYY-MM-DD')

    setInvoiceDueDate(updatedInvoiceDueDate)
    // setInvoiceDueDays(newDueDays)
    // const newDueDate = updateDueDateFromDays(newDueDays)
    // // Get the relevant invoice details
    // const updatedTransactionDetails: any = [...transactionDetails]
    // const invoiceIndex = 0 // Adjust this index based on your data structure
    // const invoice = updatedTransactionDetails?.[1]?.newInvoice?.[0]

    // // Update the due date of the invoice
    // if (invoice) {
    //   const newDueDate = updateDueDateFromDays(newDueDays)
    //   invoice.dueDate = newDueDate

    //   // Update the state with the modified transaction details
    //   setTransactionDetails(updatedTransactionDetails)
    // }
  }
  const updateDueDateFromDays = (days: any) => {
    setIsValidDate(true)
    const today = new Date(voucherDate)
    if (isNaN(today.getTime())) {
      // Handle invalid date error
      setIsValidDate(false)
      toast.error('Invalid voucher date')
      return ''
    }
    const newDueDate = new Date(today.getTime() + days * 24 * 60 * 60 * 1000)
    if (isNaN(newDueDate.getTime())) {
      // Handle invalid date error
      setIsValidDate(false)
      toast.error('Invalid due date')
      return ''
    }

    return newDueDate.toISOString().split('T')[0] // Format as 'YYYY-MM-DD'
  }

  const handleAdvanceDetailsChange = (index: any, fieldName: any, value: any) => {
    console.log(transactionDetails)
    // Make a shallow copy of the data array from transactionDetails
    const updatedData = [...transactionDetails?.data]

    const updatedAgainstAdvanceDetails = {...updatedData[index]?.advanceDetails}

    updatedAgainstAdvanceDetails[fieldName] = value

    updatedData[index].advanceDetails = updatedAgainstAdvanceDetails

    setTransactionDetails({
      ...transactionDetails,
      data: updatedData,
    })
  }

  const multiSetOffData = get(transactionDetails, 'data', [])
  console.log(multiSetOffData)
  const salesAndPurchaseView = () => {
    const sumInvoiceAmount = sum(invoiceAmount)
    const validateInvoiceAmount = isArray(invoiceAmount) ? sumInvoiceAmount : invoiceAmount

    const againstInvoiceTotalValue = sum(
      compact(
        map(
          flatten(map(get(transactionDetails, 'data', []), 'againstInvoiceDetails')),
          'settOffAmount'
        )
      )
    )

    return (
      <>
        {transactionDetails?.showOptions && (
          <>
            <div className='table-responsive'>
              <table
                id='view_transactions_table'
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
              >
                <thead>
                  <tr className='text-center text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                    <th>Voucher Number</th>
                    <th>Voucher Name</th>
                    <th>Voucher Date</th>
                    <th>Transacting currency</th>
                    <th className='w-300px'>Total Amount</th>
                    <th>NARRATIon</th>
                  </tr>
                </thead>
                <tbody className='text-gray-600 fw-bold text-center'>
                  {transactionDetails?.transactionDetails?.transactionDetails?.map(
                    (tableData: any, index: any) => {
                      return (
                        <>
                          {index === 0 && (
                            <tr className='text-center'>
                              <td>{transactionDetails?.transactionDetails?.voucherNumber}</td>
                              <td className='text-capitalize'>
                                {transactionDetails?.transactionDetails?.voucherName
                                  ? location?.pathname.split('/')[3] +
                                    ' - ' +
                                    transactionDetails?.transactionDetails?.voucherName
                                  : location?.pathname.split('/')[3]}
                              </td>
                              <td>{transactionDetails?.transactionDetails?.voucherDate}</td>
                              <td>
                                <span className='badge badge-primary'>
                                  {transactionDetails?.transactionDetails?.transactingCurrency}
                                </span>
                              </td>
                              <td>{tableData?.amount}</td>
                              <td>
                                {transactionDetails?.transactionDetails?.narration
                                  ? transactionDetails?.transactionDetails?.narration
                                  : '-'}
                              </td>
                            </tr>
                          )}
                          <tr className='text-center'>
                            <td colSpan={6}>
                              {' '}
                              <span className='fw-bolder text-dark'>
                                {tableData?.parentLedger}
                              </span>{' '}
                              - {tableData?.subLedger}
                              <span
                                className={`${
                                  tableData?.transactionType === 'debit'
                                    ? 'text-danger'
                                    : 'text-success'
                                }`}
                                style={{fontStyle: 'italic'}}
                              >
                                {tableData?.amount}
                                {tableData?.transactionType === 'credit' ? ' CR' : ' DR'}
                              </span>
                            </td>
                          </tr>
                        </>
                      )
                    }
                  )}
                </tbody>
              </table>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='text-end mb-5 d-flex w-100 align-items-center gap-2'>
                <h6>Invoice Due Days</h6>
                <input
                  type='text'
                  className='form-control w-75px'
                  value={invoiceDueDays}
                  onChange={(e: any) => {
                    setInvoiceDueDays(e.target.value)
                    handleDueDaysChange(Number(e.target.value))
                  }}
                />
              </div>
              <div className='text-end mb-5 d-flex w-100 align-items-end justify-content-end'>
                <div className='bg-light-primary text-center border d-flex flex-column border-dashed border-primary py-2 px-2 rounded'>
                  <div className='m-0 fs-7'>
                    {transactionDetails?.transactionDetails?.transactingCurrency || 'USD'}
                  </div>
                  <h3 className='m-0'>
                    {transactionDetails?.referenceAmount -
                      validateInvoiceAmount -
                      againstInvoiceTotalValue}
                  </h3>
                  <div className='m-0 fs-7'>Available Set Off Amount</div>
                </div>
              </div>
            </div>
            <div className='table-responsive'>
              <div className='d-flex justify-content-between align-items-center'>
                <h3>New Invoice</h3>
                <h4 className='fw-bolder '>
                  <span>{validateInvoiceAmount}</span>
                </h4>
              </div>
              <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                <thead>
                  <tr className='text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                    <th className='col-lg-2 '>Invoice Number</th>
                    <th className='col-lg-2 '>Due Date</th>
                    <th className='col-lg-2 '>Amount</th>
                    <th className='col-lg-2 '>Type</th>
                    <th className='col-lg-4 '>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type='text'
                        name='invoiceNumber'
                        className='form-control'
                        defaultValue={newInvoiceNumber}
                        onChange={(e) => {
                          setNewInvoiceNumber(e.target.value)
                          // handleInvoiceInputChange(
                          //   Number(e.target.value),
                          //   0,
                          //   'invoiceNumber',
                          //   0,
                          //   'newInvoice'
                          // )
                        }}
                        id=''
                      />
                    </td>
                    <td>
                      <input
                        type='date'
                        name='dueDate'
                        className='form-control'
                        value={invoiceDueDate}
                        onChange={(e) => {
                          const date1 = moment(voucherDate)
                          const date2 = moment(e.target.value).format('YYYY-MM-DD')
                          const days = date1.diff(date2, 'days')

                          if (days < 0) {
                            setInvoiceDueDays(Math.abs(days))
                            setInvoiceDueDate(date2)
                          }

                          // const diffTime = Math.abs(date2 - date1)
                          // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
                          // const updatedInvoiceDueDate = updateDueDateFromDays(e.target.value)
                          // setInvoiceDueDate(updatedInvoiceDueDate)
                          // handleInvoiceInputChange(
                          //   e.target.value,
                          //   0,
                          //   'dueDate',
                          //   0,
                          //   'newInvoice'
                          // )
                          // // Calculate the difference between the new due date and today to update Invoice Due Days
                          // const today: any = new Date()
                          // const newDueDateObject: any = new Date()
                          // const differenceInDays = Math.floor(
                          //   (newDueDateObject - today) / (24 * 60 * 60 * 1000)
                          // )
                          // setInvoiceDueDays(differenceInDays)
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type='text'
                        name='amount'
                        className='form-control'
                        value={validateInvoiceAmount}
                        onChange={(e) => {
                          const enteredAmount = Number(e.target.value)
                          const maxAmount = transactionDetails?.referenceAmount

                          const canEnterValue =
                            transactionDetails?.referenceAmount -
                              (againstInvoiceTotalValue + enteredAmount) >=
                            0
                          // Check if enteredAmount is less than or equal to maxAmount
                          if (canEnterValue) {
                            setInvoiceAmount(enteredAmount)
                            // Continue with additional logic if needed
                            // handleInvoiceInputChange(
                            //   Number(e.target.value),
                            //   0,
                            //   'amount',
                            //   0,
                            //   'newInvoice'
                            // );
                          } else {
                            // Optionally, you can show an error message or handle the validation failure in some way
                            // console.error('Entered amount exceeds the maximum allowed amount')
                            Swal.fire({
                              icon: 'error',
                              title: 'Oops...',
                              text: 'Entered amount exceeds the maximum allowed amount!',
                              toast: true,
                              position: 'top-end',
                              showConfirmButton: false,
                              timer: 3000,
                            })
                          }
                        }}
                        id=''
                      />
                    </td>
                    <td>
                      <span className='badge badge-secondary'>
                        {transactionDetails?.transactionType}
                      </span>
                    </td>
                    <td>
                      <textarea
                        name='remark'
                        className='form-control'
                        value={invoiceRemark}
                        onChange={(e) => {
                          setInvoiceRemark(e.target.value)
                          // handleInvoiceInputChange(
                          //   e.target.value,
                          //   0,
                          //   'remarks',
                          //   0,
                          //   'newInvoice'
                          // )
                        }}
                        rows={3}
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='table-responsive'>
              <div className='d-flex justify-content-between align-items-center'>
                <h3>Against Advance</h3>
                <h4 className='fw-bolder '>
                  <span>
                    {transactionDetails?.transactionDetails?.transactingCurrency}{' '}
                    {againstInvoiceAmount}
                  </span>
                </h4>
              </div>
              <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                <thead>
                  <tr className='text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                    <th className='col-1.5'>Reference Number</th>
                    <th className='col-1.5'>Date of advance</th>
                    <th className='col-1.5'>Amount</th>
                    <th className='col-1.5'>SETTLED AMOUNT</th>
                    <th className='col-1.5'> SET OFF AMOUNT </th>
                    <th className='col-3'>Type</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {transactionDetails?.againstAdvanceDetails?.map((invoice: any, index: number) => {
                    return (
                      <tr>
                        <td>
                          <input
                            type='text'
                            name='advanceNumber'
                            disabled={true}
                            className='form-control'
                            value={invoice?.advanceNumber}
                            onChange={(e) =>
                              handleAdvanceDetailsChange(index, 'advanceNumber', e.target.value)
                            }
                            id=''
                          />
                        </td>
                        <td>
                          <input
                            type='date'
                            name='dateOfAdvance'
                            disabled={true}
                            className='form-control'
                            value={invoice?.dateOfAdvance}
                            onChange={(e) =>
                              handleAdvanceDetailsChange(index, 'dateOfAdvance', e.target.value)
                            }
                            id=''
                          />
                        </td>
                        <td>
                          {' '}
                          <input
                            type='number'
                            name='amount'
                            disabled={true}
                            className='form-control'
                            value={invoice?.amount}
                            onChange={(e) => {
                              const value = Number(e.target.value)
                              setAgainstInvoiceAmount(value)
                            }}
                            id=''
                          />
                        </td>
                        <td>
                          {' '}
                          <input
                            type='number'
                            name='settledInvoiceAmount'
                            disabled={true}
                            className='form-control'
                            value={invoice?.settledInvoiceAmount}
                            onChange={(e) => {
                              const value = Number(e.target.value)
                              setAgainstInvoiceAmount(value)
                            }}
                            id=''
                          />
                        </td>
                        <td>
                          {' '}
                          <input
                            type='number'
                            name='setOff'
                            className='form-control'
                            value={invoice?.setOff}
                            onChange={(e) => {
                              // const canEnterValue =
                              //   item?.referenceAmount - (sumAgainstInvoiceAmount + enteredAmount) >=
                              //   0
                              handleAdvanceDetailsChange(index, 'setOff', e.target.value)
                            }}
                            id=''
                          />
                        </td>

                        <td>
                          <span className='badge badge-secondary'>{invoice?.transactionType}</span>
                        </td>
                        <td>
                          <textarea
                            name='remark'
                            className='form-control'
                            value={invoice?.remarks}
                            disabled={true}
                            onChange={(e) =>
                              handleAdvanceDetailsChange(index, 'remarks', e.target.value)
                            }
                            rows={3}
                          ></textarea>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              {
                <div className='my-5 p-5  fw-bolder fs-5 bg-light-primary rounded  border border-dashed border-primary'>
                  The set-off amount{' '}
                  {transactionDetails?.referenceAmount -
                    validateInvoiceAmount -
                    againstInvoiceTotalValue}{' '}
                  will be marked under no reference.{' '}
                </div>
              }
            </div>
            <div className='card-footer text-end'>
              <button
                className='btn btn-primary me-5'
                disabled={transactionDetails?.length > 0 ? true : false}
                onClick={() => {
                  handleCreateTransaction()
                }}
              >
                Save
              </button>
              <button
                className='btn btn-danger'
                onClick={() => {
                  setModal(true)
                  // navigate(previousPath)
                }}
              >
                Discard
              </button>
            </div>
            <ImageModal
              show={modal}
              onHide={() => {
                setModal(false)
                window.location.reload()
              }}
              falseStatus={() => {
                setStatus(false)
              }}
            />
          </>
        )}
        {!transactionDetails?.showOptions && (
          <div className='card-body'>
            <table
              id='view_transactions_table'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            >
              <thead>
                <tr className='text-center text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                  <th>Voucher Number</th>
                  <th>Voucher Name</th>
                  <th>Voucher Date</th>
                  <th>Transacting currency</th>
                  <th className='w-300px'>Total Amount</th>
                  <th>NARRATIon</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-bold text-center'>
                {transactionDetails?.transactionDetails?.transactionDetails?.map(
                  (tableData: any, index: any) => {
                    return (
                      <>
                        {index === 0 && (
                          <tr className='text-center'>
                            <td>{transactionDetails?.transactionDetails?.voucherNumber}</td>
                            <td className='text-capitalize'>
                              {location?.pathname.split('/')[3]}
                              {' - '}
                              {transactionDetails?.transactionDetails?.voucherName
                                ? transactionDetails?.transactionDetails?.voucherName
                                : location?.pathname.split('/')[3]}
                            </td>
                            <td>{transactionDetails?.transactionDetails?.voucherDate}</td>
                            <td>
                              <span className='badge badge-primary'>
                                {transactionDetails?.transactionDetails?.transactingCurrency}
                              </span>
                            </td>
                            <td>
                              {tableData?.transactionAmount
                                ? tableData?.transactionAmount
                                : tableData?.amount}
                            </td>
                            <td>
                              {transactionDetails?.transactionDetails?.narration
                                ? transactionDetails?.transactionDetails?.narration
                                : '-'}
                            </td>
                          </tr>
                        )}
                        <tr className='text-center border-bottom'>
                          <td colSpan={6}>
                            {' '}
                            <span className='fw-bolder text-dark'>
                              {tableData?.parentLedger}
                            </span> - {tableData?.subLedger}
                            <span
                              className={`${
                                tableData?.transactionType === 'debit'
                                  ? 'text-danger'
                                  : 'text-success'
                              }`}
                              style={{fontStyle: 'italic'}}
                            >
                              {tableData?.amount}{' '}
                              {tableData?.transactionType === 'credit' ? ' CR' : ' DR'}
                            </span>
                          </td>
                        </tr>
                      </>
                    )
                  }
                )}
              </tbody>
            </table>
            {transactionDetails?.message?.length > 0 && (
              <div className='my-5 p-5  fw-bolder fs-5 bg-light-primary rounded  border border-dashed border-primary'>
                {transactionDetails?.message?.map((message: any) => {
                  return message
                })}
              </div>
            )}
            <div className='card-footer text-end'>
              <button
                className='btn btn-primary me-5'
                disabled={transactionDetails?.length > 0 ? true : false}
                onClick={() => {
                  handleCreateTransaction()
                }}
              >
                Save
              </button>
              <button
                className='btn btn-danger'
                onClick={() => {
                  setModal(true)
                  // navigate(previousPath)
                }}
              >
                Discard
              </button>
            </div>
            <ImageModal
              show={modal}
              onHide={() => {
                setModal(false)
                window.location.reload()
              }}
              falseStatus={() => {
                setStatus(false)
              }}
            />
          </div>
        )}
      </>
    )
  }
  function totalCOunting() {
    if (!isEmpty(multiSetOffData)) {
      let totalInvoiceAmount: any = []
      let totalAgainstAmount: any = []
      multiSetOffData.forEach((item: any) => {
        let itemTotalInvoiceAmount: any = 0
        console.log(item.againstInvoiceDetails)
        item.againstInvoiceDetails.forEach((invoice: any, index: number) => {
          // if (index === 0) {
          //   itemTotalInvoiceAmount = invoice?.settledInvoiceAmount
          // } else itemTotalInvoiceAmount += invoice.settledInvoiceAmount
          itemTotalInvoiceAmount = itemTotalInvoiceAmount + (invoice.settOffAmount || 0)
        })

        totalInvoiceAmount.push(itemTotalInvoiceAmount)
        totalAgainstAmount.push(
          item.advanceDetails === undefined && item?.advanceDetails?.amount === undefined
            ? 0
            : item?.advanceDetails?.amount
        )
      })

      // Filter out undefined elements
      const filteredInvoiceAmount = totalInvoiceAmount.filter((amount: any) => amount !== undefined)
      const filteredAgainstAmount = totalAgainstAmount.filter((amount: any) => amount !== undefined)
      console.log(totalAgainstAmount)
      setInvoiceAmount(filteredInvoiceAmount)
      setAgainstInvoiceAmount(filteredAgainstAmount)
    }
  }

  // const validateOtherEnteredAmount = () => {
  //   const againstInvoiceTotalValue = sum(
  //     compact(
  //       map(
  //         flatten(map(get(transactionDetails, 'data', []), 'againstInvoiceDetails')),
  //         'settOffAmount'
  //       )
  //     )
  //   )
  //   const sumAgainstInvoiceAmount = sum(againstInvoiceAmount)

  // }
  useEffect(() => {
    totalCOunting()
  }, [transactionDetails])

  const otherVoucherView = () => {
    console.log(transactionDetails)
    const againstInvoiceTotalValue = sum(
      compact(
        map(
          flatten(map(get(transactionDetails, 'data', []), 'againstInvoiceDetails')),
          'settOffAmount'
        )
      )
    )
    console.log(againstInvoiceTotalValue)
    // const sumAgainstInvoiceAmount = sum(againstInvoiceAmount)

    return multiSetOffData.map((item: any, index: number) => {
      console.log(item)
      console.log(item?.referenceAmount)
      const calculatedReferenceAmount =
        item?.referenceAmount - invoiceAmount[index] - againstInvoiceAmount[index]

      return (
        <>
          {item?.showOptions && (
            <>
              <div className='accordion' id={`accordionExample${index}`}>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingOne'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target={`#collapse${index}`}
                      aria-expanded='true'
                      aria-controls={`collapse${index}`}
                    >
                      <div className='text-start text-muted fw-bolder bg-light ps-4 fs-5 text-uppercase gs-0'>
                        {item?.setOffInvoiceParentLedger}
                      </div>
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className='accordion-collapse collapse show'
                    aria-labelledby='headingOne'
                    data-bs-parent={`#accordionExample${index}`}
                  >
                    <div className='accordion-body'>
                      <div className='d-flex align-items-center justify-content-between'>
                        {/* <div className='text-end mb-5 d-flex w-100 align-items-center gap-2'>
                    <h6>Invoice Due Days</h6>
                    <input
                      type='text'
                      className='form-control w-75px'
                      value={invoiceDueDays}
                      onChange={(e: any) => {
                        setInvoiceDueDays(e.target.value)
                        handleDueDaysChange(Number(e.target.value))
                      }}
                    />
                  </div> */}
                        <div className='text-end mb-5 d-flex w-100 align-items-end justify-content-end'>
                          <div className='bg-light-primary text-center border d-flex flex-column border-dashed border-primary py-2 px-2 rounded'>
                            <div className='m-0 fs-7'>
                              {transactionDetails?.transactionDetails?.transactingCurrency || 'USD'}
                            </div>
                            {/* <h3 className='m-0'>{item?.referenceAmount}</h3> */}
                            <h3 className='m-0'>
                              {calculatedReferenceAmount ? calculatedReferenceAmount : 0}
                            </h3>
                            <div className='m-0 fs-7'>Pending Reference Amount</div>
                          </div>
                        </div>
                      </div>

                      <div className='table-responsive'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <h3>New Advance</h3>
                          <h4 className='fw-bolder '>
                            <span>
                              {transactionDetails?.transactionDetails?.transactingCurrency}{' '}
                              {againstInvoiceAmount[index]}
                            </span>
                          </h4>
                        </div>
                        <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                          <thead>
                            <tr className='text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                              <th>Reference Number</th>
                              <th>Date of Advance</th>
                              <th>Amount</th>
                              <th>Type</th>
                              <th>Remarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  type='text'
                                  name='advanceNumber'
                                  disabled={true}
                                  className='form-control'
                                  value={item?.advanceDetails?.advanceNumber}
                                  onChange={(e) =>
                                    handleAdvanceDetailsChange(
                                      index,
                                      'advanceNumber',
                                      e.target.value
                                    )
                                  }
                                  id=''
                                />
                              </td>
                              <td>
                                <input
                                  type='date'
                                  name='dateOfAdvance'
                                  disabled={true}
                                  className='form-control'
                                  value={item?.advanceDetails?.dateOfAdvance}
                                  onChange={(e) =>
                                    handleAdvanceDetailsChange(
                                      index,
                                      'dateOfAdvance',
                                      e.target.value
                                    )
                                  }
                                  id=''
                                />
                              </td>

                              <td>
                                <input
                                  type='number'
                                  name='amount'
                                  className='form-control'
                                  value={item?.advanceDetails?.amount}
                                  onChange={(e) => {
                                    const enteredAmount = Number(e.target.value)
                                    const canEnterValue =
                                      item?.referenceAmount -
                                        (invoiceAmount[index] + enteredAmount) >=
                                      0

                                    // If the entered amount is less than or equal to the previous amount, update it
                                    if (canEnterValue) {
                                      handleAdvanceDetailsChange(index, 'amount', enteredAmount)
                                    } else {
                                      // Otherwise, check if the new amount exceeds the maximum allowed amount
                                      if (canEnterValue) {
                                        handleAdvanceDetailsChange(index, 'amount', enteredAmount)
                                      } else {
                                        totalCOunting()
                                        Swal.fire({
                                          icon: 'error',
                                          title: 'Oops...',
                                          text: 'Entered amount exceeds the maximum allowed amount!',
                                          toast: true,
                                          position: 'top-end',
                                          showConfirmButton: false,
                                          timer: 3000,
                                        })
                                      }
                                    }
                                  }}
                                  id=''
                                />
                              </td>

                              <td>
                                <span className='badge badge-secondary'>
                                  {item?.advanceDetails?.transactionType}
                                </span>
                              </td>
                              <td>
                                <textarea
                                  name='remark'
                                  className='form-control'
                                  value={item?.advanceDetails?.remarks}
                                  onChange={(e) =>
                                    handleAdvanceDetailsChange(index, 'remarks', e.target.value)
                                  }
                                  rows={3}
                                ></textarea>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className='table-responsive'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <h3>
                            {isEmpty(multiSetOffData) ? 'Against Invoice' : 'Against Invoice'}
                          </h3>
                          <h4 className='fw-bolder '>
                            <span>
                              {transactionDetails?.transactionDetails?.transactingCurrency}{' '}
                              {invoiceAmount[index]}
                            </span>
                          </h4>
                        </div>
                        <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                          <thead>
                            <tr className='text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                              <th className='col-1.5'>Invoice Number</th>
                              <th className='col-1.5'>Date of Invoice</th>
                              <th className='col-1.5'>Amount</th>
                              <th className='col-1.5'>SETTLED AMOUNT</th>
                              <th className='col-1.5'> SET OFF </th>
                              <th className='col-1.5'>Type</th>
                              <th className='col-3'>Remarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item?.againstInvoiceDetails?.map(
                              (invoice: any, invoiceIndex: number) => {
                                console.log(invoice)
                                return (
                                  <tr>
                                    <td>
                                      <input
                                        type='text'
                                        name='invoiceNumber'
                                        disabled={true}
                                        className='form-control'
                                        value={invoice?.invoiceNumber}
                                        onChange={(e) => {
                                          setNewInvoiceNumber(e.target.value)
                                          handleInvoiceInputChange(
                                            Number(e.target.value),
                                            invoiceIndex,
                                            'invoiceNumber',
                                            index,
                                            'againstInvoiceDetails'
                                          )
                                        }}
                                        id=''
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type='date'
                                        name='dueDate'
                                        className='form-control'
                                        value={invoice.invoiceDueDate}
                                        disabled={true}
                                        onChange={(e) => {
                                          const date1 = moment(voucherDate)
                                          const date2 = moment(e.target.value).format('YYYY-MM-DD')
                                          const days = date1.diff(date2, 'days')

                                          if (days < 0) {
                                            setInvoiceDueDays(Math.abs(days))
                                            setInvoiceDueDate(date2)
                                          }

                                          // const diffTime = Math.abs(date2 - date1)
                                          // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
                                          // const updatedInvoiceDueDate = updateDueDateFromDays(e.target.value)
                                          // setInvoiceDueDate(updatedInvoiceDueDate)
                                          handleInvoiceInputChange(
                                            moment(e.target.value).format('YYYY-MM-DD'),
                                            invoiceIndex,
                                            'invoiceDueDate',
                                            index,
                                            'againstInvoiceDetails'
                                          )
                                          // // Calculate the difference between the new due date and today to update Invoice Due Days
                                          // const today: any = new Date()
                                          // const newDueDateObject: any = new Date()
                                          // const differenceInDays = Math.floor(
                                          //   (newDueDateObject - today) / (24 * 60 * 60 * 1000)
                                          // )
                                          // setInvoiceDueDays(differenceInDays)
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type='text'
                                        disabled={true}
                                        name='amount'
                                        className='form-control'
                                        value={invoice?.invoiceAmount}
                                        onChange={(e) => {
                                          const enteredAmount = Number(e.target.value)
                                          const maxAmount = item?.referenceAmount

                                          // Check if enteredAmount is less than or equal to maxAmount
                                          if (enteredAmount <= maxAmount) {
                                            // Continue with additional logic if needed
                                            handleInvoiceInputChange(
                                              enteredAmount,
                                              invoiceIndex,
                                              'invoiceAmount',
                                              index,
                                              'againstInvoiceDetails'
                                            )
                                          } else {
                                            Swal.fire({
                                              icon: 'error',
                                              title: 'Oops...',
                                              text: 'Entered amount exceeds the maximum allowed amount!',
                                              toast: true,
                                              position: 'top-end',
                                              showConfirmButton: false,
                                              timer: 3000, // Adjust the timer as needed
                                            })
                                          }
                                        }}
                                        id=''
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type='text'
                                        disabled={true}
                                        name='amount'
                                        className='form-control'
                                        value={get(
                                          invoice,
                                          'settledInvoiceAmount',
                                          get(invoice, 'settledAdvanceAmount', 0)
                                        )}
                                        id=''
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type='number'
                                        name='setOff'
                                        className='form-control'
                                        value={invoice?.settOffAmount}
                                        onChange={(e) => {
                                          const enteredAmount = Number(e.target.value)
                                          const canEnterValue =
                                            item?.referenceAmount -
                                              (againstInvoiceAmount[index] + enteredAmount) >=
                                              0 &&
                                            invoice.invoiceAmount - invoice.settledInvoiceAmount >=
                                              enteredAmount
                                          if (canEnterValue) {
                                            // Allow if the entered amount decreases from the old value
                                            totalCOunting()
                                            handleInvoiceInputChange(
                                              enteredAmount,
                                              invoiceIndex,
                                              'settOffAmount',
                                              index,
                                              'againstInvoiceDetails'
                                            )
                                          } else {
                                            totalCOunting()
                                            Swal.fire({
                                              icon: 'error',
                                              title: 'Oops...',
                                              text: 'Entered amount exceeds the maximum allowed amount!',
                                            })
                                          }
                                        }}
                                        id=''
                                      />
                                    </td>
                                    <td>
                                      <span className='badge badge-secondary'>
                                        {item?.advanceDetails?.transactionType}
                                      </span>
                                    </td>
                                    <td>
                                      <textarea
                                        name='remark'
                                        className='form-control'
                                        disabled={true}
                                        value={invoice?.remarks}
                                        onChange={(e) => {
                                          setInvoiceRemark(e.target.value)
                                          handleInvoiceInputChange(
                                            e.target.value,
                                            invoiceIndex,
                                            'remarks',
                                            index,
                                            'againstInvoiceDetails'
                                          )
                                        }}
                                        rows={3}
                                      ></textarea>
                                    </td>
                                  </tr>
                                )
                              }
                            )}
                          </tbody>
                        </table>
                        {
                          <div className='my-5 p-5  fw-bolder fs-5 bg-light-primary rounded  border border-dashed border-primary'>
                            The set-off amount{' '}
                            {item?.referenceAmount -
                              invoiceAmount[index] -
                              againstInvoiceAmount[index]}{' '}
                            will be marked under no reference.{' '}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <ImageModal
            show={modal}
            onHide={() => {
              setModal(false)

              // window.location.reload()
            }}
            falseStatus={() => {
              setStatus(false)
              navigate('/transactions/enter/journal')
            }}
          />
        </>
      )
    })
  }
  console.log(transactionDetails)
  // if (loading || error) return <Preloader />

  return (
    <>
      {status === false ? (
        <>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body border-bottom p-6'>
              <div className='row'>
                <div className='row col-lg-9'>
                  <div className='col-lg-3'>
                    <Select
                      value={voucherName}
                      onChange={(option) => setVoucherName(option as OptionType)}
                      options={voucherOptions}
                      isSearchable={true}
                      styles={{
                        container: (baseStyles) => ({
                          ...baseStyles,
                          maxWidth: '95%',
                        }),
                        singleValue: (styles) => ({
                          ...styles,
                          color: 'black',
                          fontWeight: 600,
                        }),
                        placeholder: (styles) => ({
                          ...styles,
                          color: 'black',
                        }),
                      }}
                      components={{IndicatorSeparator: () => null}}
                      placeholder='Voucher Name'
                    />
                  </div>
                  <div className='col-lg-2'>
                    <div style={{width: '100%'}}>
                      <input
                        type='date'
                        value={voucherDate}
                        onChange={(e) => setVoucherDate(e.target.value)}
                        style={{
                          height: '38px',
                          maxWidth: '95%',
                          width: 'inherit',
                          outline: '0!important',
                          borderColor: 'hsl(0, 0%, 80%)',
                          borderRadius: '4px',
                          borderStyle: 'solid',
                          borderWidth: '1px',
                          boxSizing: 'border-box',
                          padding: '2px 8px',
                        }}
                        placeholder='Voucher Date'
                      />
                      {!isValidDate && (
                        <span style={{color: 'red', fontSize: '12px'}}>Invalid date</span>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-2'>
                    <Select
                      value={currency}
                      onChange={(option) => setCurrency(option as OptionType)}
                      options={currencyOptions}
                      isSearchable={true}
                      styles={{
                        container: (baseStyles) => ({
                          ...baseStyles,
                          maxWidth: '95%',
                        }),
                        singleValue: (styles) => ({
                          ...styles,
                          color: 'black',
                          fontWeight: 600,
                        }),
                        placeholder: (styles) => ({
                          ...styles,
                          color: 'black',
                        }),
                      }}
                      components={{IndicatorSeparator: () => null}}
                      placeholder='Currency (*)'
                    />
                  </div>
                </div>
                <div className='col-lg-3 d-flex justify-content-between'>
                  <button
                    type='button'
                    className='btn btn-secondary btn-sm me-2'
                    style={{maxHeight: '50px'}}
                    onClick={() => navigate(`/transactions/view/${activeTab}`)}
                  >
                    <KTIcon iconName='arrow-right' className='fs-4' />
                    View Transactions
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary btn-sm'
                    style={{maxHeight: '50px'}}
                    onClick={() => setCreateLedger(true)}
                  >
                    <KTIcon iconName='plus-circle' className='fs-4' />
                    New Ledger
                  </button>
                </div>
              </div>
            </div>

            {/* {(activeTab === 'sales' || activeTab === 'purchase') && (
              <div className='card-body border-bottom py-3 px-6'>
                <div className='row'>
                  <div className='row col-lg-9'>
                    <div className='col-lg-4'>
                      <input
                        placeholder='Invoice Number'
                        type='text'
                        className='form-control mb-3 mb-lg-0'
                        autoComplete='off'
                        style={{maxWidth: '95%'}}
                        value={invoiceNumber}
                        onChange={(e) => setInvoiceNumber(e.target.value)}
                      />
                    </div>
                    <div className='col-lg-4'>
                      <input
                        placeholder='Invoice Description'
                        type='text'
                        className='form-control mb-3 mb-lg-0'
                        autoComplete='off'
                        style={{maxWidth: '95%'}}
                        value={invoiceDescription}
                        onChange={(e) => setInvoiceDescription(e.target.value)}
                      />
                    </div>
                    <div className='col-lg-4'>
                      <input
                        type='number'
                        className='form-control mb-3 mb-lg-0 disabled'
                        disabled={true}
                        autoComplete='off'
                        style={{maxWidth: '95%'}}
                        value={activeTab === 'sales' ? debitTotal : creditTotal}
                      />
                    </div>
                  </div>
                  <div className='col-lg-3'></div>
                </div>
              </div>
            )} */}

            <div className='card-body border-bottom mx-3 px-6 py-3 text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
              <div className='row'>
                <label className='col-lg-2 '></label>
                <label className='col-lg-5 '></label>
                <label className='col-lg-2 text-center'>DEBIT</label>
                <label className='col-lg-2 text-center'>CREDIT</label>
                <label className='col-lg-1'></label>
              </div>
            </div>

            <div className='card-body border-bottom p-3'>
              {data.map((d, i) => (
                <TransactionRow
                  key={i}
                  row={d}
                  index={i}
                  ledgerCurrentBalanceMapping={ledgerCurrentBalanceMapping}
                  data={data}
                  setData={setData}
                  byLedgerDetails={byLedgerDetails}
                  toLedgerDetails={toLedgerDetails}
                  showConversion={currency?.value !== reportingCurrency}
                  applicableRate={applicableRate}
                  reportingCurrency={reportingCurrency}
                />
              ))}

              <div className='row border-bottom pb-3'>
                <div className='col-lg-11'></div>
                <div className='col-lg-1 d-flex flex-center flex-column gap-5'>
                  <button
                    type='button'
                    className='btn btn-success btn-lg'
                    style={{
                      height: '50px',
                      width: '50px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={() =>
                      setData((prev) => [
                        ...prev,
                        {transactionType: 'debit', parentLedger: '', subLedger: '', amount: 0},
                      ])
                    }
                  >
                    <KTIcon iconName='message-add' className='fs-1 px-0' />
                  </button>
                </div>
              </div>

              <div className='row p-3'>
                <label className='col-lg-2 '></label>
                <label className='col-lg-5 '></label>
                <label className='col-lg-2'>
                  <CardsWidget7
                    className='border border-dashed border-gray-300'
                    stats={formatNumber(debitTotal)}
                    description='Total Debit'
                  />
                </label>
                <label className='col-lg-2'>
                  <CardsWidget7
                    className='border border-dashed border-gray-300'
                    stats={formatNumber(creditTotal)}
                    description='Total Credit'
                  />
                </label>
                <label className='col-lg-1'></label>
              </div>
            </div>
            {setOffDone && (
              <div className='px-6'>
                <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed p-6'>
                  <KTIcon iconName='design-frame' className='fs-2tx text-primary me-4' />
                  <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-bold'>
                      <div className='fs-6 text-gray-600'>Set off has been applied.</div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!loading && (
              <div className='card-body border-bottom p-6'>
                <textarea
                  className='form-control'
                  value={narration}
                  onChange={(e) => setNarration(e.target.value)}
                  placeholder='Narration'
                />
              </div>
            )}

            {!loading && (
              <div className='card-body d-flex flex-end flex-column p-6 me-4 gap-5'>
                {/* {(activeTab === 'journal' ||
                activeTab === 'receipt' ||
                activeTab === 'debitnote' ||
                activeTab === 'creditnote' ||
                activeTab === 'payment') && (
                <button
                  type='button'
                  className={`btn btn-warning me-3`}
                  onClick={handleSetOff}
                  disabled={!debitTotal || !creditTotal || debitTotal !== creditTotal}
                >
                  <i className='fas fa-arrows-up-to-line fs-3' />
                  Set Off
                </button>
              )} */}

                {/* <button
                type='button'
                className={`btn btn-primary me-4 ${
                  (debitTotal !== creditTotal ||
                    !currency?.value ||
                    data.some((d) => !d.parentLedger || !d.amount)) &&
                  'disabled'
                }`}
                onClick={handleCreateTransaction}
                disabled={
                  debitTotal !== creditTotal ||
                  !currency?.value ||
                  data.some((d) => !d.parentLedger || !d.amount)
                }
              >
                <i className='far fa-thin fa-floppy-disk fs-3' />
                {loading ? 'Saving...' : 'Save'}
              </button> */}
                {/* <button
                type='button'
                className={`btn btn-primary me-4 `}
                disabled={
                  debitTotal !== creditTotal ||
                  !currency?.value ||
                  data.some((d) => !d.parentLedger || !d.amount)
                }
                onClick={() => {
                  handleCreateNewTransaction(2)
                }}
              >
                <i className='far fa-thin fa-floppy-disk fs-3' />
                {loading ? 'Saving...' : 'Save 2'}
              </button> */}
                {/* <button
                type='button'
                className={`btn btn-primary me-4`}
                disabled={
                  debitTotal !== creditTotal ||
                  !currency?.value ||
                  data.some((d) => !d.parentLedger || !d.amount)
                }
                onClick={() => {
                  handleCreateNewTransaction(3)
                }}
              >
                <i className='far fa-thin fa-floppy-disk fs-3' />
                {loading ? 'Saving...' : 'Save 3'}
              </button> */}
                {errorMessage && <InfoAlert message={errorMessage} />}
                <button
                  type='button'
                  className={`btn btn-primary d-flex align-items-center next-button`}
                  disabled={
                    debitTotal !== creditTotal ||
                    !currency?.value ||
                    data.some((d) => !d.parentLedger || !d.amount) ||
                    nextDisabled
                  }
                  onClick={() => {
                    handleCreateNewTransaction()
                  }}
                >
                  <span>{loading ? 'Saving...' : 'Next'}</span>
                  <KTIcon iconName='double-right' className={`fs-4 pe-0`} />
                </button>
              </div>
            )}
            {loading && <Loader isError={error} />}
          </div>
          <Modal
            className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
            role='dialog'
            data-backdrop='false'
            aria-hidden='true'
            tabIndex='-1'
            show={createLedger}
            animation={false}
            size='xl'
            contentClassName='top-0'
          >
            <CreateEditLedger setCreateLedger={setCreateLedger} editLedger={editLedger} />
          </Modal>
          <Modal
            className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
            id='disable_financial_year'
            role='dialog'
            data-backdrop='false'
            aria-hidden='true'
            tabIndex='-1'
            show={currencyModal}
            animation={false}
          >
            <div className='modal-content'>
              <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
                <h5 className='fw-bold m-0'>Currency Exchange Rate</h5>
                <div className='d-flex ms-2'>
                  <div
                    className='btn btn-icon btn-sm btn-light-primary ms-2'
                    data-bs-dismiss='modal'
                    onClick={() => setCurrencyModal(false)}
                  >
                    <KTIcon className='fs-1' iconName='cross' />
                  </div>
                </div>
              </div>

              <div className='py-5 px-5'>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>Reporting Currency:</label>

                  <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>{reportingCurrency}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>Transaction Currency:</label>

                  <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>{currency?.value || ''}</span>
                  </div>
                </div>

                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>Fetched Rate:</label>

                  <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>
                      {exchangeRate?.exchangeRate || 0} (as on {exchangeRate?.asOnDate})
                    </span>
                  </div>
                </div>

                <div className='row mb-7 d-flex align-items-center'>
                  <label className='col-lg-4 fw-bold text-muted'>Applicable Rate:</label>

                  <div className='col-lg-8'>
                    <input
                      type='number'
                      className='form-control'
                      value={applicableRate <= 0 ? '' : applicableRate}
                      placeholder='Applicable Rate'
                      onChange={(e) =>
                        Number(e.target.value) >= 0 && setApplicableRate(Number(e.target.value))
                      }
                    />
                  </div>
                </div>

                <div className='row mb-7'>
                  <input
                    className='col-lg-2'
                    type='checkbox'
                    checked={updateExchangeRate}
                    onChange={() => setUpdateExchangeRate((prev) => !prev)}
                  />
                  <label className='col-lg-10 fw-bolder fs-6 text-dark'>
                    Update this exchange rate in the system
                  </label>
                </div>
              </div>

              <div className='d-flex flex-end p-5'>
                <button
                  type='reset'
                  onClick={() => setCurrencyModal(false)}
                  className='btn btn-light me-3'
                  data-kt-users-modal-action='cancel'
                >
                  Cancel
                </button>

                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  onClick={() => setCurrencyModal(false)}
                >
                  Proceed
                </button>
              </div>
            </div>
          </Modal>
          <Modal
            size='lg'
            className='modal-sticky modal-sticky-lg'
            data-backdrop='false'
            aria-hidden='true'
            show={setOffModal}
            animation={false}
          >
            <div className='modal-content='>
              {/*begin::Header*/}
              <div className='modal-header d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
                <h5 className='fs-2 fw-bold m-0'>Set Off Invoices</h5>
                <div className='d-flex ms-2'>
                  {/*begin::Close*/}
                  <div
                    className='btn btn-icon btn-sm btn-light-primary ms-2'
                    data-bs-dismiss='modal'
                    onClick={() => setSetOffModal(false)}
                  >
                    <KTIcon className='fs-1' iconName='cross' />
                  </div>
                  {/*end::Close*/}
                </div>
              </div>
              {/*end::Header*/}

              <div className='modal-body'>
                {invoiceDetails?.length ? (
                  <div className='flex-row-fluid'>
                    <div className='row mb-2 border-bottom p-1'>
                      <div className='col-lg-3 text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                        Invoice Number
                      </div>
                      <div className='col-lg-3 text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                        Invoice Amount
                      </div>
                      <div className='col-lg-3 text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                        Settled Amount
                      </div>
                      <div className='col-lg-3 text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                        Set Off Amount
                      </div>
                    </div>
                    {invoiceDetails?.map((invoice, index) => (
                      <div className='row mb-9 pb-3 d-flex flex-center border-bottom'>
                        <div className='col-lg-3 fw-bolder'>{invoice.invoiceNumber}</div>
                        <div className='col-lg-3'>
                          {formatNumber(invoice.invoiceAmount / (exchangeRate?.exchangeRate ?? 1))}
                        </div>
                        <div className='col-lg-3'>
                          {formatNumber(
                            invoice.settledInvoiceAmount / (exchangeRate?.exchangeRate ?? 1)
                          )}
                        </div>
                        <div className='col-lg-3 '>
                          <input
                            value={invoice.setOffAmount}
                            onChange={(e) => {
                              const invDetails = [...invoiceDetails]
                              invDetails[index].setOffAmount = Number(e.target.value)
                              setInvoiceDetails(invDetails)
                            }}
                            type='number'
                            className='form-control'
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    <span className='fs-4 fw-bold'>No Invoices found</span>
                  </div>
                )}
                <div className='d-flex flex-end'>
                  <button
                    type='reset'
                    onClick={() => setSetOffModal(false)}
                    className='btn btn-light me-3 btn-small'
                    data-kt-users-modal-action='cancel'
                  >
                    Cancel
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary btn-small'
                    data-kt-users-modal-action='submit'
                    disabled={
                      invoiceDetails?.reduce(
                        (acc, val) => (acc = acc + (val?.setOffAmount || 0)),
                        0
                      ) !== setOffTotalAmount
                    }
                    onClick={handleSetOffLogic}
                  >
                    Save Transaction
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <div className='card'>
          <div className='card-header'>
            <div className='card-title'>
              <Link
                to={previousPath}
                onClick={() => {
                  setPreviousPath(location.pathname)
                }}
              >
                <div
                  data-bs-toggle='tooltip'
                  data-bs-trigger='hover'
                  data-bs-dismiss-='click'
                  title='Go Back'
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                  style={{marginRight: '5px'}}
                >
                  <KTIcon iconName='arrow-left' className='fs-2' />
                </div>
              </Link>
              Transaction details
            </div>
          </div>
          <div className='card-body'>
            {!isEmpty(multiSetOffData) && (
              <div className='table-responsive'>
                <table
                  id='view_transactions_table'
                  className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                >
                  <thead>
                    <tr className='text-center text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                      <th>Voucher Number</th>
                      <th>Voucher Name</th>
                      <th>Voucher Date</th>
                      <th>Transacting currency</th>
                      <th className='w-300px'>Total Amount</th>
                      <th>NARRATIon</th>
                    </tr>
                  </thead>
                  <tbody className='text-gray-600 fw-bold text-center'>
                    {transactionDetails?.transactionDetails?.transactionDetails?.map(
                      (tableData: any, index: any) => {
                        return (
                          <>
                            {index === 0 && (
                              <tr className='text-center'>
                                <td>{transactionDetails?.transactionDetails?.voucherNumber}</td>
                                <td className='text-capitalize'>
                                  {transactionDetails?.transactionDetails?.voucherName
                                    ? location?.pathname.split('/')[3] +
                                      ' - ' +
                                      transactionDetails?.transactionDetails?.voucherName
                                    : location?.pathname.split('/')[3]}
                                </td>
                                <td>{transactionDetails?.transactionDetails?.voucherDate}</td>
                                <td>
                                  <span className='badge badge-primary'>
                                    {transactionDetails?.transactionDetails?.transactingCurrency}
                                  </span>
                                </td>
                                <td>{tableData?.amount}</td>
                                <td>
                                  {transactionDetails?.transactionDetails?.narration
                                    ? transactionDetails?.transactionDetails?.narration
                                    : '-'}
                                </td>
                              </tr>
                            )}
                            <tr className='text-center'>
                              <td colSpan={6}>
                                {' '}
                                <span className='fw-bolder text-dark'>
                                  {tableData?.parentLedger}
                                </span>{' '}
                                - {tableData?.subLedger}
                                <span
                                  className={`${
                                    tableData?.transactionType === 'debit'
                                      ? 'text-danger'
                                      : 'text-success'
                                  }`}
                                  style={{fontStyle: 'italic'}}
                                >
                                  {tableData?.amount}{' '}
                                  {tableData?.transactionType === 'credit' ? ' CR' : ' DR'}
                                </span>
                              </td>
                            </tr>
                          </>
                        )
                      }
                    )}
                  </tbody>
                </table>
              </div>
            )}
            {isEmpty(multiSetOffData) ? salesAndPurchaseView() : otherVoucherView()}
            {!isEmpty(multiSetOffData) && (
              <div className='card-footer text-end'>
                <button
                  className='btn btn-primary me-5'
                  disabled={transactionDetails?.length > 0 ? true : false}
                  onClick={() => {
                    handleCreateTransaction()
                  }}
                >
                  Save
                </button>
                <button
                  className='btn btn-danger'
                  onClick={() => {
                    setModal(true)
                    // navigate(previousPath)
                  }}
                >
                  Discard
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

const ImageModal = ({show, onHide, falseStatus}: any) => {
  return (
    <Modals show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Do you want to discard</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Display images here */}
        <div className='row'>
          <div className='col-12 text-end'>
            <Button variant='success' className='me-5' onClick={onHide}>
              Save Changes
            </Button>
            <Button
              variant='secondary'
              onClick={() => {
                window.history.replaceState({}, '')
                onHide()
                falseStatus()
              }}
            >
              Discard Changes
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modals>
  )
}
