import React, {useEffect, useRef, useState, useMemo} from 'react'
import Modal from 'react-bootstrap/Modal'
import {KTCard, KTIcon, KTCardBody, useDebounce} from '../../../../_metronic/helpers'
import {ColumnInstance, Row, useTable} from 'react-table'
import {useLayout} from '../../../../_metronic/layout/core'
import {Transaction, getTransactionDetails} from '../API/ViewTransactionsAPI'
import Select from 'react-select'
import Swal from 'sweetalert2'
import {useMatch, useNavigate} from 'react-router-dom'
import {Loader} from '../../product-master/loader'

interface ViewTransactionModalProps {
  showViewTransactionModal: boolean
  setShowViewTransactionModal: React.Dispatch<React.SetStateAction<boolean>>
  transactionData: any
  handleDelete: any
  showDeleteModal: boolean
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
}
const ViewTransactionModal: React.FC<ViewTransactionModalProps> = ({
  showViewTransactionModal,
  setShowViewTransactionModal,
  transactionData,
  handleDelete,
  showDeleteModal,
  setShowDeleteModal,
}) => {
  const navigate = useNavigate()
  const {company, financialYear} = useLayout()

  const [loading, setLoading] = useState(false)
  const [transactionDetails, setTransactionDetails]: any = useState()
  const [ledgerOptions, setLedgerOptions]: any = useState(null)
  const [selectedLedger, setSelectedLedger]: any = useState(null)
  useEffect(() => {
    async function getTData() {
      const res: any = await getTransactionDetails(
        company.value,
        financialYear.value,
        transactionData.voucherType,
        transactionData.dbId,
        transactionData.voucherNumber
      )
      setTransactionDetails(res)
      setLoading(false)
      res &&
        setLedgerOptions(
          res.transactionDetails.slice(1).map((transaction: any) => {
            return {label: transaction.parentLedger, value: transaction.ledgerCode}
          })
        )
    }
    if (company && financialYear && showViewTransactionModal) {
      setLoading(true)
      getTData()
    }
  }, [company, financialYear, transactionData, showViewTransactionModal])

  useEffect(() => {
    if (ledgerOptions && ledgerOptions.length > 0) {
      setSelectedLedger(ledgerOptions[0])
    }
  }, [ledgerOptions])

  console.log(transactionDetails)
  console.log(ledgerOptions)
  console.log(selectedLedger)

  let selectedLedgerAdvanceDetails =
    transactionDetails &&
    selectedLedger &&
    transactionDetails.advanceDetails.filter(
      (adv: any) => adv.ledger_code === selectedLedger.value
    )[0]
  let selectedLedgerAgainstInvoiceDetails =
    transactionDetails &&
    selectedLedger &&
    transactionDetails.againstInvoiceDetails.filter(
      (adv: any) => adv.parent_ledger === selectedLedger.label
    )
  console.log(selectedLedgerAgainstInvoiceDetails)
  return (
    <Modal
      show={showViewTransactionModal}
      fullscreen={true}
      onHide={() => setShowViewTransactionModal(false)}
      centered={true}
      // size='xl'
      contentClassName='position-relative top-0'
      dialogClassName='h-100'
    >
      <Modal.Header closeButton>
        <Modal.Title>Transaction Details</Modal.Title>
      </Modal.Header>
      {loading ? (
        <Loader height='80vh' />
      ) : (
        <>
          <Modal.Body>
            <KTCard className='py-4'>
              <div className='table-responsive'>
                <table
                  id='view_transactions_table'
                  className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                >
                  <thead>
                    <tr className='text-center text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                      <th>Voucher Number</th>
                      <th>Voucher Name</th>
                      <th>Voucher Date</th>
                      <th>Transacting currency</th>
                      <th className='w-300px'>Total Amount</th>
                      <th>NARRATIon</th>
                    </tr>
                  </thead>
                  <tbody className='text-gray-600 fw-bold text-center'>
                    {transactionDetails?.transactionDetails?.map((tableData: any, index: any) => {
                      return (
                        <React.Fragment key={index}>
                          {index === 0 && (
                            <tr className='text-center'>
                              <td>{transactionDetails?.voucherNumber}</td>
                              <td className='text-capitalize'>{transactionDetails?.voucherName}</td>
                              <td>{transactionDetails?.voucherDate}</td>
                              <td>
                                <span className='badge badge-primary'>
                                  {transactionDetails?.transactingCurrency}
                                </span>
                              </td>
                              <td>{tableData?.transactionAmount}</td>
                              <td>
                                {transactionDetails?.narration
                                  ? transactionDetails?.narration
                                  : '-'}
                              </td>
                            </tr>
                          )}
                          <tr className='text-center'>
                            <td colSpan={6}>
                              {' '}
                              <span className='fw-bolder text-dark'>
                                {tableData?.parentLedger}
                              </span>{' '}
                              - {tableData?.subLedger}
                              <span
                                className={`${
                                  tableData?.transactionType === 'debit'
                                    ? 'text-danger'
                                    : 'text-success'
                                }`}
                                style={{fontStyle: 'italic'}}
                              >
                                {tableData?.transactionAmount}{' '}
                                {tableData?.transactionType === 'credit' ? ' CR' : ' DR'}
                              </span>
                            </td>
                          </tr>
                        </React.Fragment>
                      )
                    })}
                  </tbody>
                </table>
              </div>

              {transactionDetails &&
                transactionDetails.voucherType !== 'sales' &&
                transactionDetails.voucherType !== 'purchase' && (
                  <div className='col-lg-5 mb-5'>
                    <div className='d-flex flex-row gap-5'>
                      <div className='col-6'>
                        <Select
                          value={selectedLedger}
                          onChange={(option) => {
                            // if (option?.value) {
                            setSelectedLedger(option)
                            // }
                          }}
                          styles={{
                            singleValue: (styles) => ({
                              ...styles,
                              color: 'black',
                              fontWeight: 600,
                            }),
                            placeholder: (styles) => ({
                              ...styles,
                              color: 'black',
                            }),
                          }}
                          options={ledgerOptions}
                          isSearchable={true}
                          components={{IndicatorSeparator: () => null}}
                          placeholder='Choose a Ledger (*)'
                        />
                      </div>
                    </div>
                  </div>
                )}
              {selectedLedger && (
                <div className='col'>
                  <div className='d-flex justify-content-between align-items-center'>
                    {transactionDetails.voucherType === 'sales' ||
                    transactionDetails.voucherType === 'purchase' ? (
                      <h3>New Invoice</h3>
                    ) : (
                      <h3>New Advance</h3>
                    )}
                  </div>
                  {transactionDetails.voucherType !== 'sales' &&
                  transactionDetails.voucherType !== 'purchase' ? (
                    selectedLedgerAdvanceDetails ? (
                      <div className='table-responsive'>
                        <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                          <thead>
                            <tr className='text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                              <th>Reference Number</th>
                              <th>Date of Advance</th>
                              <th>Amount</th>
                              <th>Type</th>
                              <th>Remarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  type='text'
                                  name='advanceNumber'
                                  disabled={true}
                                  className='form-control'
                                  value={selectedLedgerAdvanceDetails.advance_number}
                                />
                              </td>
                              <td>
                                <input
                                  type='date'
                                  name='dateOfAdvance'
                                  disabled={true}
                                  className='form-control'
                                  value={selectedLedgerAdvanceDetails.advance_date}
                                />
                              </td>

                              <td>
                                <input
                                  type='number'
                                  name='amount'
                                  className='form-control'
                                  disabled={true}
                                  value={selectedLedgerAdvanceDetails.advance_amount}
                                />
                              </td>

                              <td>
                                <span className='badge badge-secondary'>
                                  {selectedLedgerAdvanceDetails.transaction_type}
                                </span>
                              </td>
                              <td>
                                <textarea
                                  name='remark'
                                  className='form-control'
                                  disabled={true}
                                  value={selectedLedgerAdvanceDetails?.advance_description}
                                  rows={3}
                                ></textarea>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className='my-5 p-5  fw-bolder fs-5 bg-light-primary rounded  border border-dashed border-primary'>
                        No Advance
                      </div>
                    )
                  ) : transactionDetails.invoiceDetails.length > 0 ? (
                    <div className='table-responsive'>
                      <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                        <thead>
                          <tr className='text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                            <th className='col-lg-2 '>Invoice Number</th>
                            <th className='col-lg-2 '>Due Date</th>
                            <th className='col-lg-2 '>Amount</th>
                            <th className='col-lg-2 '>Type</th>
                            <th className='col-lg-4 '>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                type='text'
                                name='invoiceNumber'
                                className='form-control'
                                disabled
                                value={transactionDetails.invoiceDetails[0].invoice_number}
                              />
                            </td>
                            <td>
                              <input
                                type='date'
                                name='dueDate'
                                className='form-control'
                                disabled
                                value={transactionDetails.invoiceDetails[0].invoice_due_date}
                              />
                            </td>
                            <td>
                              <input
                                type='text'
                                name='amount'
                                className='form-control'
                                value={transactionDetails.invoiceDetails[0].invoice_amount}
                                disabled
                              />
                            </td>
                            <td>
                              <span className='badge badge-secondary'>
                                {transactionDetails.invoiceDetails[0].transaction_type}
                              </span>
                            </td>
                            <td>
                              <textarea
                                name='remark'
                                disabled
                                className='form-control'
                                value={transactionDetails.invoiceDetails[0].invoice_description}
                                rows={3}
                              ></textarea>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className='my-5 p-5  fw-bolder fs-5 bg-light-primary rounded  border border-dashed border-primary'>
                      No Invoice
                    </div>
                  )}
                </div>
              )}
              {selectedLedger && (
                <div className='col'>
                  <div className='d-flex justify-content-between align-items-center'>
                    {transactionDetails.voucherType === 'sales' ||
                    transactionDetails.voucherType === 'purchase' ? (
                      <h3>Against Advance</h3>
                    ) : (
                      <h3>Against Invoice</h3>
                    )}
                  </div>
                  {transactionDetails.voucherType !== 'sales' &&
                  transactionDetails.voucherType !== 'purchase' ? (
                    selectedLedgerAgainstInvoiceDetails &&
                    selectedLedgerAgainstInvoiceDetails.length > 0 ? (
                      <div className='table-responsive'>
                        <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                          <thead>
                            <tr className='text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                              <th>Invoice Number</th>
                              <th>Date of Invoice</th>
                              <th>Amount</th>
                              <th>SETTLED AMOUNT</th>
                              <th>SET OFF</th>
                              <th>Type</th>
                              <th>Remarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedLedgerAgainstInvoiceDetails.map((invoice: any) => (
                              <tr>
                                <td>
                                  <input
                                    type='text'
                                    name='invoiceNumber'
                                    disabled={true}
                                    className='form-control'
                                    value={invoice?.invoice_number}
                                  />
                                </td>
                                <td>
                                  <input
                                    type='date'
                                    name='dueDate'
                                    className='form-control'
                                    value={invoice.invoice_due_date}
                                    disabled={true}
                                  />
                                </td>
                                <td>
                                  <input
                                    type='text'
                                    disabled={true}
                                    name='amount'
                                    className='form-control'
                                    value={invoice?.invoice_amount}
                                  />
                                </td>
                                <td>
                                  <input
                                    type='text'
                                    disabled={true}
                                    name='amount'
                                    className='form-control'
                                    value={invoice.settled_amount || 0}
                                  />
                                </td>
                                <td>
                                  <input
                                    type='number'
                                    name='setOff'
                                    disabled
                                    className='form-control'
                                    value={invoice?.settOffAmount}
                                  />
                                </td>
                                <td>
                                  <span className='badge badge-secondary'>
                                    {invoice.transaction_type}
                                  </span>
                                </td>
                                <td>
                                  <textarea
                                    name='remark'
                                    className='form-control'
                                    disabled={true}
                                    value={invoice?.remarks}
                                    rows={3}
                                  ></textarea>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className='my-5 p-5  fw-bolder fs-5 bg-light-primary rounded  border border-dashed border-primary'>
                        No Invoice
                      </div>
                    )
                  ) : transactionDetails.againstAdvanceDetails.length > 0 ? (
                    <div className='table-responsive'>
                      <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                        <thead>
                          <tr className='text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                            <th className='col-1.5'>Reference Number</th>
                            <th className='col-1.5'>Date of advance</th>
                            <th className='col-1.5'>Amount</th>
                            <th className='col-1.5'>SETTLED AMOUNT</th>
                            <th className='col-1.5'> SET OFF AMOUNT </th>
                            <th className='col-3'>Type</th>
                            <th>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactionDetails?.againstAdvanceDetails?.map(
                            (invoice: any, index: number) => {
                              return (
                                <tr>
                                  <td>
                                    <input
                                      type='text'
                                      name='advanceNumber'
                                      disabled={true}
                                      className='form-control'
                                      value={invoice?.advance_number}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type='date'
                                      name='dateOfAdvance'
                                      disabled={true}
                                      className='form-control'
                                      value={invoice?.date_of_advance}
                                    />
                                  </td>
                                  <td>
                                    {' '}
                                    <input
                                      type='number'
                                      name='amount'
                                      disabled={true}
                                      className='form-control'
                                      value={invoice?.advance_amount}
                                    />
                                  </td>
                                  <td>
                                    {' '}
                                    <input
                                      type='number'
                                      name='settledInvoiceAmount'
                                      disabled={true}
                                      className='form-control'
                                      value={invoice?.settled_advance_amount}
                                    />
                                  </td>
                                  <td>
                                    {' '}
                                    <input
                                      type='number'
                                      name='setOff'
                                      className='form-control'
                                      value={invoice?.setOff_amount}
                                    />
                                  </td>

                                  <td>
                                    <span className='badge badge-secondary'>
                                      {invoice?.transaction_type}
                                    </span>
                                  </td>
                                  <td>
                                    <textarea
                                      name='remark'
                                      className='form-control'
                                      value={invoice?.remarks}
                                      disabled={true}
                                      rows={3}
                                    ></textarea>
                                  </td>
                                </tr>
                              )
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className='my-5 p-5  fw-bolder fs-5 bg-light-primary rounded  border border-dashed border-primary'>
                      No Advance
                    </div>
                  )}
                </div>
              )}
            </KTCard>
          </Modal.Body>
          <Modal.Footer>
            <button
              // type='button'
              className={`btn btn-danger`}
              onClick={() => {
                setShowDeleteModal(true)
              }}
            >
              Delete Transaction
            </button>
            <button
              // type='button'
              className='btn btn-primary'
              onClick={() =>
                navigate(`/transactions/enter/${transactionData.voucherType}`, {
                  state: {
                    transaction: transactionDetails,
                  },
                })
              }
            >
              {/* <KTIcon iconName='plus-circle' className='fs-4' /> */}
              Edit Transaction
            </button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}

export default ViewTransactionModal
