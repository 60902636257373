import {createColumnHelper} from '@tanstack/react-table'
import {KTIcon} from '../../../_metronic/helpers'

export interface IBalanceSheet {
  voucherType?: string
  group?: string
  subGroup?: string
  ledger?: string
  credit: string | null
  debit: string | null
  children?: IBalanceSheet[]
}

const columnHelper = createColumnHelper<IBalanceSheet>()

const getBalanceSheetColumns1 = (from?: string, to?: string) => [
  columnHelper.accessor('voucherType', {
    header: () => <span className='px-3'>Classification</span>,
    cell: ({row, getValue}) => {
      return <div className='max-w-125px px-3 fw-bolder text-primary fs-2'>{getValue()}</div>
    },
  }),
  columnHelper.accessor('group', {
    header: () => <span className='min-w-125px'>Group</span>,
    cell: ({row, getValue}) => {
      return (
        <div className='min-w-125px'>
          {row.getCanExpand() && row.original.children && (
            <>
              {row.depth === 1 && (
                <span
                  {...{
                    onClick: (e) => {
                      e.stopPropagation()
                      row.toggleExpanded()
                    },
                    style: {cursor: 'pointer'},
                  }}
                >
                  {row.getIsExpanded() ? (
                    <KTIcon iconName='up' className='text-dark fs-2' />
                  ) : (
                    <KTIcon iconName='down' className='text-dark fs-2' />
                  )}
                </span>
              )}
            </>
          )}{' '}
          <a
            href={`/transactions/trial-balance/group?parent_group=${getValue()}&from=${from}&to=${to}`}
            className='text-dark fw-bolder text-hover-primary'
          >
            {getValue()}
          </a>
        </div>
      )
    },
  }),
  columnHelper.accessor('subGroup', {
    header: () => <span className='min-w-125px'>Sub Group</span>,
    cell: ({row, getValue}) => {
      return (
        <div className='min-w-125px'>
          {row.getCanExpand() && row.original.children && (
            <>
              {row.depth === 2 && (
                <span
                  {...{
                    onClick: (e) => {
                      e.stopPropagation()
                      row.toggleExpanded()
                    },
                    style: {cursor: 'pointer'},
                  }}
                >
                  {row.getIsExpanded() ? (
                    <KTIcon iconName='up' className='text-dark fs-2' />
                  ) : (
                    <KTIcon iconName='down' className='text-dark fs-2' />
                  )}
                </span>
              )}
            </>
          )}{' '}
          <a
            href={`/transactions/trial-balance/group?parent_group=${
              row.getParentRow()?.original.group
            }&sub_group=${getValue()}&from=${from}&to=${to}`}
            className='text-dark fw-semibold text-hover-primary'
          >
            {getValue()}
          </a>
        </div>
      )
    },
  }),
  columnHelper.accessor('ledger', {
    header: () => <span className='min-w-125px'>Ledger</span>,
    cell: ({row, getValue}) => {
      const parentRow = row.getParentRow()
      let url = `/transactions/trial-balance/ledger?parent_group=${
        parentRow?.getParentRow()?.original.group
      }&sub_group=${parentRow?.original.subGroup}&from=${from}&to=${to}`
      const ledger = getValue()
      if (ledger && ledger.includes('-')) {
        const [parent_ledger, sub_ledger] = ledger.split('-')
        url += `&parent_ledger=${parent_ledger.trim()}&sub_ledger=${sub_ledger.trim()}`
      } else url += `&parent_ledger=${ledger}`
      return (
        <a href={url} className='text-muted text-hover-primary'>
          <em>{getValue()}</em>
        </a>
      )
    },
  }),
  columnHelper.accessor('debit', {
    header: () => <span className='min-w-125px'>Debit</span>,
    cell: ({row, table}) => (
      <>
        {row.depth > 0 && (
          <div
            className={
              row.depth === 1
                ? 'text-dark fw-bold'
                : row.depth === 2
                ? 'text-dark fw-semibold'
                : 'text-muted ki-text-italic'
            }
          >
            {row.original.debit ?? '-'}
          </div>
        )}
      </>
    ),
  }),
  columnHelper.accessor('credit', {
    header: () => <span className='min-w-125px'>Credit</span>,
    cell: ({row, table}) => (
      <>
        {row.depth > 0 && (
          <div
            className={
              row.depth === 1
                ? 'text-dark fw-bold'
                : row.depth === 2
                ? 'text-dark fw-semibold'
                : 'text-muted'
            }
          >
            {row.original.credit ? row.original.credit : '-'}
          </div>
        )}
      </>
    ),
  }),
]

const getBalanceSheetColumns2 = (from?: string, to?: string) => [
  columnHelper.accessor('voucherType', {
    header: () => <span className='px-3'>Classification</span>,
    cell: ({row, getValue}) => {
      return <div className='max-w-125px px-3 fw-bolder text-primary fs-2'>{getValue()}</div>
    },
  }),
  columnHelper.accessor('group', {
    header: () => <span className='min-w-125px'>Group</span>,
    cell: ({row, getValue}) => {
      return (
        <div className='min-w-125px'>
          {row.getCanExpand() && row.original.children && (
            <>
              {row.depth === 1 && (
                <span
                  {...{
                    onClick: (e) => {
                      e.stopPropagation()
                      row.toggleExpanded()
                    },
                    style: {cursor: 'pointer'},
                  }}
                >
                  {row.getIsExpanded() ? (
                    <KTIcon iconName='up' className='text-dark fs-2' />
                  ) : (
                    <KTIcon iconName='down' className='text-dark fs-2' />
                  )}
                </span>
              )}
            </>
          )}{' '}
          <a
            href={`/transactions/trial-balance/group?parent_group=${getValue()}&from=${from}&to=${to}`}
            className='text-dark fw-bolder text-hover-primary'
          >
            {getValue()}
          </a>
        </div>
      )
    },
  }),
  columnHelper.accessor('subGroup', {
    header: () => <span className='min-w-125px'>Sub Group</span>,
    cell: ({row, getValue}) => {
      return (
        <div className='min-w-125px'>
          {row.getCanExpand() && row.original.children && (
            <>
              {row.depth === 2 && (
                <span
                  {...{
                    onClick: (e) => {
                      e.stopPropagation()
                      row.toggleExpanded()
                    },
                    style: {cursor: 'pointer'},
                  }}
                >
                  {row.getIsExpanded() ? (
                    <KTIcon iconName='up' className='text-dark fs-2' />
                  ) : (
                    <KTIcon iconName='down' className='text-dark fs-2' />
                  )}
                </span>
              )}
            </>
          )}{' '}
          <a
            href={`/transactions/trial-balance/group?parent_group=${
              row.getParentRow()?.original.group
            }&sub_group=${getValue()}&from=${from}&to=${to}`}
            className='text-dark fw-semibold text-hover-primary'
          >
            {getValue()}
          </a>
        </div>
      )
    },
  }),
  columnHelper.accessor('ledger', {
    header: () => <span className='min-w-125px'>Ledger</span>,
    cell: ({row, getValue}) => {
      const parentRow = row.getParentRow()
      let url = `/transactions/trial-balance/ledger?parent_group=${
        parentRow?.getParentRow()?.original.group
      }&sub_group=${parentRow?.original.subGroup}&from=${from}&to=${to}`
      const ledger = getValue()
      if (ledger && ledger.includes('-')) {
        const [parent_ledger, sub_ledger] = ledger.split('-')
        url += `&parent_ledger=${parent_ledger.trim()}&sub_ledger=${sub_ledger.trim()}`
      } else url += `&parent_ledger=${ledger}`
      return (
        <a href={url} className='text-muted text-hover-primary'>
          <em>{getValue()}</em>
        </a>
      )
    },
  }),
  columnHelper.accessor('debit', {
    header: () => <span className='min-w-125px'>Debit</span>,
    cell: ({row, table}) => (
      <>
        {row.depth > 0 && (
          <div
            className={
              row.depth === 1
                ? 'text-dark fw-bold'
                : row.depth === 2
                ? 'text-dark fw-semibold'
                : 'text-muted ki-text-italic'
            }
          >
            {row.original.debit ?? '-'}
          </div>
        )}
      </>
    ),
  }),
  columnHelper.accessor('credit', {
    header: () => <span className='min-w-125px'>Credit</span>,
    cell: ({row, table}) => (
      <>
        {row.depth > 0 && (
          <div
            className={
              row.depth === 1
                ? 'text-dark fw-bold'
                : row.depth === 2
                ? 'text-dark fw-semibold'
                : 'text-muted'
            }
          >
            {row.original.credit ? row.original.credit : '-'}
          </div>
        )}
      </>
    ),
  }),
]

export {getBalanceSheetColumns1, getBalanceSheetColumns2}
