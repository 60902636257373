import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {KTCard, KTCardBody, KTIcon, useDebounce} from '../../../../_metronic/helpers'

import {getGroupColumns} from './columns'
import {ProductGroup, getProductGroups, downloadPdf, downloadProductGroupTable} from './api'

import {useLayout} from '../../../../_metronic/layout/core'
import {CustomRow, styles, CustomHeaderColumn} from './utils'
import {CreateEditGroup} from './CreateEditGroup'
import toast from 'react-hot-toast'
import jsPDF from 'jspdf'
import {Loader} from '../loader'

export const Group = () => {
  const {company, financialYear} = useLayout()
  console.log(company.value)
  console.log(financialYear.value)
  const tableRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [createGroup, setCreateGroup] = useState(false)
  const [data, setData] = useState<ProductGroup[]>([])
  const [editGroup, setEditGroup] = useState<ProductGroup | undefined>(undefined)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const columns = useMemo(() => getGroupColumns(setEditGroup, setCreateGroup), [])

  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  const filteredData: ProductGroup[] = useMemo(() => {
    if (debouncedSearchTerm?.length) {
      return data.filter(
        (d) =>
          d.classification.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          d.parentGroup.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          d?.subGroup?.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      )
    }
    return []
  }, [debouncedSearchTerm, data])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: debouncedSearchTerm?.length ? filteredData : data,
  })

  const getAllGroups = useCallback(async () => {
    if (company) {
      try {
        setIsError(false)
        setIsLoading(true)
        const res = await getProductGroups(company?.value)
        res && setData(res)
      } catch (err) {
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    }
  }, [company])

  useEffect(() => {
    if (!createGroup) {
      getAllGroups()
      setEditGroup(undefined)
    }
  }, [getAllGroups, createGroup])

  return (
    <>
      <KTCard>
        {createGroup ? (
          <CreateEditGroup setCreateGroup={setCreateGroup} editGroup={editGroup} />
        ) : (
          <>
            <div className='card-header border-0 pt-6'>
              <div className='card-title'>
                {/* begin::Search */}
                <div className='d-flex align-items-center position-relative my-1'>
                  <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                  <input
                    type='text'
                    data-kt-user-table-filter='search'
                    className='form-control form-control-solid w-250px ps-14'
                    placeholder='Search'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                {/* end::Search */}
              </div>
              {/* begin::Card toolbar */}
              <div className='card-toolbar'>
                {/* begin::Group actions */}
                {
                  <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                    <button
                      type='button'
                      className='btn btn-primary me-2'
                      disabled={!data.length}
                      onClick={async () => {
                        toast.success('Download has been started successfully')
                        const response = await downloadProductGroupTable(
                          data,
                          company.value,
                          financialYear.value
                        )

                        console.log(response)
                        const pdfData = response.data
                        const blob = new Blob([pdfData], {type: 'application/pdf'})
                        const url = window.URL.createObjectURL(blob)
                        const link: any = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', 'file.pdf') // The name of the downloaded file
                        document.body.appendChild(link)
                        link.click()

                        // // Clean up and revoke the object URL
                        link.parentNode.removeChild(link)
                        window.URL.revokeObjectURL(url)
                        // const doc = new jsPDF({
                        //   format: 'a2',
                        //   unit: 'px',
                        //   hotfixes: ['px_scaling'],
                        // })

                        // tableRef.current &&
                        //   doc.html(tableRef.current, {
                        //     callback: (doc) => {
                        //       const pageWidth = doc.internal.pageSize.getWidth()
                        //       const pageHeight = doc.internal.pageSize.getHeight()

                        //       const header = `${company.label} | ${financialYear.value}`
                        //       doc.setFontSize(26)
                        //       doc.setFont('Arial', 'italic', 600)
                        //       doc.text(header, (pageWidth - 300) / 2, 30)

                        //       const footer = `${new Date().getFullYear().toString()}© String Labs`
                        //       doc.setFontSize(20)
                        //       doc.text(footer, 30, pageHeight - 20 - 10)
                        //       const totalPages = doc.getNumberOfPages()

                        //       doc.deletePage(totalPages - 1)
                        //       doc.deletePage(totalPages - 2)
                        //       doc.deletePage(totalPages - 4)
                        //       doc.deletePage(totalPages - 3)

                        //       doc.save(
                        //         `Product Master - Groups - ${company.label}(${financialYear.value})`
                        //       )
                        //     },
                        //     margin: [80, 30, 30, 30],
                        //     autoPaging: 'text',
                        //   })
                      }}
                    >
                      <KTIcon iconName='arrow-down' className='fs-2' />
                      Download
                    </button>

                    {/* begin::Add user */}
                    <button
                      type='button'
                      className='btn btn-primary'
                      onClick={() => setCreateGroup(true)}
                    >
                      <KTIcon iconName='plus' className='fs-2' />
                      Add Group
                    </button>
                    {/* end::Add user */}
                  </div>
                }
                {/* end::Group actions */}
              </div>
              {/* end::Card toolbar */}
            </div>
            <KTCardBody className='py-4'>
              {!isLoading && (
                <div className='table-responsive'>
                  <table
                    ref={tableRef}
                    id='pm_group_table'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    {...getTableProps()}
                  >
                    <thead>
                      <tr className='text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                        {headers.map((column: ColumnInstance<ProductGroup>) => (
                          <CustomHeaderColumn key={column.id} column={column} />
                        ))}
                      </tr>
                    </thead>
                    <tbody className='text-gray-600 text-center fw-bold' {...getTableBodyProps()}>
                      {rows.length > 0 ? (
                        rows.map((row: Row<ProductGroup>, i) => {
                          prepareRow(row)
                          return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                        })
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                              No matching records found
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
              {isLoading && <Loader isError={isError} />}
            </KTCardBody>
          </>
        )}
      </KTCard>
    </>
  )
}
