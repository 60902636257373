import {Route, Routes} from 'react-router-dom'
import {TrialBalance} from './TrialBalance'
import {LedgerDetails} from './LedgerDetails'
import {GroupAndSubGroupDetails} from './GroupSub-GroupDetails'

export const TrialBalanceIndex = () => {
  return (
    <Routes>
      <Route index element={<TrialBalance />} />
      <Route path='group' element={<GroupAndSubGroupDetails />} />
      <Route path='ledger' element={<LedgerDetails />} />
    </Routes>
  )
}
