import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import EnterTransactions from './EnterTransactions'
import ViewTransactions from './ViewTransactions'
import AgingReport from './Aging Report'
import {TrialBalanceIndex} from './TrialBalance'

const Transactions: React.FC = () => {
  return (
    <Routes>
      <Route
        path='enter/*'
        element={
          <>
            <PageTitle>Enter Transactions</PageTitle>
            <EnterTransactions />
          </>
        }
      />
      <Route
        path='view/*'
        element={
          <>
            <PageTitle>View Transactions</PageTitle>
            <ViewTransactions />
          </>
        }
      />
      <Route
        path='trial-balance/*'
        element={
          <>
            <PageTitle>Trial Balance</PageTitle>
            <TrialBalanceIndex />
          </>
        }
      />
      <Route
        path='aging-report/*'
        element={
          <>
            <PageTitle>Aging Report</PageTitle>
            <AgingReport />
          </>
        }
      />
    </Routes>
  )
}

export default Transactions
