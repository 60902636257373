import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import Modal from 'react-bootstrap/Modal'
interface DeleteTransactionModalProps {
  showDeleteModal: boolean
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
  handleDelete: any
}
const DeleteTransactionModal: React.FC<DeleteTransactionModalProps> = ({
  showDeleteModal,
  setShowDeleteModal,
  handleDelete,
}) => {
  return (
    <Modal
      className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
      id='disable_financial_year'
      role='dialog'
      data-backdrop='true'
      aria-hidden='true'
      tabIndex='-1'
      show={showDeleteModal}
      animation={false}
      style={{
        zIndex: '1100',
      }}
    >
      <div className='modal-content'>
        {/*begin::Header*/}
        <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
          <h5 className='fw-bold m-0'>Delete Transaction</h5>
          <div className='d-flex ms-2'>
            {/*begin::Close*/}
            <div
              className='btn btn-icon btn-sm btn-light-primary ms-2'
              data-bs-dismiss='modal'
              onClick={() => setShowDeleteModal(false)}
            >
              <KTIcon className='fs-1' iconName='cross' />
            </div>
            {/*end::Close*/}
          </div>
        </div>
        {/*end::Header*/}
        <div className='text-center py-5 px-5'>
          Are you sure you want to delete this Transaction? Please note you cannot undo this action.
        </div>
        <div className='text-center pt-5 pb-5'>
          <button
            type='reset'
            onClick={() => setShowDeleteModal(false)}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
          >
            Cancel
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            onClick={handleDelete}
          >
            Proceed
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteTransactionModal
