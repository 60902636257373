import {useEffect, useMemo, useState} from 'react'

import {useLayout} from '../../../../_metronic/layout/core'
import {
  GroupMapping,
  LedgerMapping,
  ProductLedger,
  createProductLedger,
  getLedgerGroupMapping,
} from './api'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {KTIcon} from '../../../../_metronic/helpers'
import toast from 'react-hot-toast'
import {CurrencyDetails, getAllCurrencyOptions} from '../currency/api'
import Select from 'react-select'

const productLedgerSchema = Yup.object().shape({
  ledgerName: Yup.string().required('Ledger name is required'),
  isSubLedger: Yup.bool().required('It is required'),
  parentLedger: Yup.string(),
  parentGroup: Yup.string(),
  subGroup: Yup.string(),
  ledgerCode: Yup.string().required('Ledger Code is required'),
  openingBalance: Yup.number().min(0),
  balanceType: Yup.string().oneOf(['credit', 'debit']).required('Balance Type is required'),
  email: Yup.string(),
  personName: Yup.string(),
  address: Yup.string(),
  state: Yup.string(),
  country: Yup.string(),
  zipCode: Yup.string(),
  bankAccountNumber: Yup.string(),
  bankBranchName: Yup.string(),
  bankIFSCCode: Yup.string(),
})

interface Ledger {
  ledgerName: string
  isSubLedger: boolean
  parentLedger: string
  parentGroup: string
  subGroup: string | null
  ledgerCode: string
  openingBalance: number
  balanceType: string
  email?: string
  personName?: string
  address?: string
  state?: string
  country?: string
  zipCode?: string
  bankAccountNumber?: string
  bankBranchName?: string
  bankIFSCCode?: string
  currency?: string
}

type OptionType = {
  value: string
  label: string
}

export const CreateEditLedger: React.FC<{
  setCreateLedger: React.Dispatch<React.SetStateAction<boolean>>
  editLedger?: ProductLedger
}> = ({setCreateLedger, editLedger}) => {
  const {company, financialYear, setFinancialYear} = useLayout()
  console.log(financialYear)
  const [loading, setLoading] = useState(false)
  const [ledgerMapping, setLedgerMapping] = useState<LedgerMapping>()
  const [groupMapping, setGroupMapping] = useState<GroupMapping>()
  const [startDate, setStartDate] = useState('')

  const initialValues: Ledger = {
    ledgerName: editLedger?.isParentLedger ? editLedger?.parentLedger : editLedger?.subLedger || '',
    isSubLedger: !!editLedger?.isParentLedger,
    parentLedger: editLedger?.parentLedger || '',
    parentGroup: editLedger?.parentGroup || '',
    subGroup: editLedger?.subGroup || '',
    ledgerCode: editLedger?.ledgerCode || '',
    openingBalance: editLedger?.openingBalance ?? 0,
    balanceType: editLedger?.transactionType ?? 'debit',
    personName: editLedger?.personName || '',
    address: editLedger?.address || '',
    state: editLedger?.state || '',
    country: editLedger?.country || '',
    zipCode: editLedger?.zipcode || '',
    bankAccountNumber: editLedger?.account_number || '',
    bankBranchName: editLedger?.branch_name || '',
    bankIFSCCode: editLedger?.ifsc_code || '',
    currency: editLedger?.currency || '',
  }

  const formik = useFormik<Ledger>({
    initialValues,
    validationSchema: productLedgerSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      setLoading(true)
      const body = {
        dbId: editLedger?.dbId,
        companyId: company?.value || '',
        isSubLedger: values?.isSubLedger,
        subLedgerName: values?.isSubLedger ? values.ledgerName : null,
        parentLedgerName: values?.isSubLedger ? values.parentLedger : values.ledgerName,
        parentGroupName: values.parentGroup,
        subGroupName: values.subGroup?.length ? values.subGroup : null,
        ledgerCode: values.ledgerCode,
        openingBalance: values.openingBalance,
        balanceType: values.balanceType,
        asOnDate: financialYear.value,
        email: values?.email,
        personName: values?.personName,
        address: values?.address,
        state: values?.state,
        country: values?.country,
        zipcode: values?.zipCode,
        accountNumber: values?.bankAccountNumber,
        branchName: values?.bankBranchName,
        ifscCode: values?.bankIFSCCode,
        currency,
      }
      await createProductLedger(body)
        .then((res) => {
          res?.detail && toast.success('The requested ledger has been added successfully.')
          formik.resetForm()
          setCreateLedger(false)
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoading(false))
    },
  })

  useEffect(() => {
    if (company && financialYear) {
      getLedgerGroupMapping(company?.value, financialYear?.value).then((res) => {
        res && setLedgerMapping(res?.ledgerMapping)
        res && setGroupMapping(res?.groupMapping)
        res && setStartDate(res?.startDate)
      })
    }
  }, [company, financialYear])

  const [currencyOptions, setCurrencyOptions] = useState<CurrencyDetails[]>([])
  const [currency, setCurrency] = useState('INR')

  useMemo(async () => {
    const res = await getAllCurrencyOptions()
    res && setCurrencyOptions(res)
  }, [])

  return (
    <div className='card'>
      {/* <div
        className='card-header border-0 '
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <div
            data-bs-toggle='tooltip'
            data-bs-trigger='hover'
            data-bs-dismiss-='click'
            title='Go Back'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            style={{marginRight: '5px'}}
            onClick={() => setCreateLedger(false)}
          >
            <KTIcon iconName='arrow-left' className='fs-2' />
          </div>
          <h3 className='fw-bolder m-0'>{!!editLedger ? 'Update Ledger' : 'Add New Ledger'}</h3>
        </div>
      </div> */}

      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        <div
          id='kt_account_profile_details'
          className='collapse show d-flex justify-content-center flex-column flex-md-row'
        >
          <div className='p-8 col pb-0 pb-md-4'>
            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label required fw-bold fs-6'>Ledger Name</label>

              <div className='col-lg-9 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Ledger Name'
                  {...formik.getFieldProps('ledgerName')}
                />
                {formik.touched.ledgerName && formik.errors.ledgerName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.ledgerName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className='required'>Is Sub Ledger</span>
              </label>

              <div className='col-lg-9 d-flex align-items-center'>
                <div className='form-check form-check-solid form-switch fv-row'>
                  <input
                    className='form-check-input w-45px h-30px'
                    type='checkbox'
                    id='isSubLedger'
                    checked={formik.values.isSubLedger}
                    onChange={() => formik.setFieldValue('isSubLedger', !formik.values.isSubLedger)}
                  />
                  <label className='form-check-label'></label>
                </div>
              </div>
            </div>

            {formik.values.isSubLedger ? (
              <>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>
                    <span className='required'>Parent Ledger</span>
                  </label>

                  <div className='col-lg-9 fv-row'>
                    <select
                      className='form-select form-select-solid form-select-lg'
                      {...formik.getFieldProps('parentLedger')}
                    >
                      <option value=''>Select a Parent Ledger...</option>
                      {ledgerMapping &&
                        Object.keys(ledgerMapping).map((ledger) => (
                          <option key={ledger} value={ledger}>
                            {ledger}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>
                    <span className='required'>Parent Group</span>
                  </label>

                  <div className='col-lg-9 fv-row'>
                    <select
                      className='form-select form-select-disabled form-select-lg'
                      value={formik.values.parentLedger}
                      disabled={true}
                    >
                      <option value={formik.values.parentLedger}>
                        {ledgerMapping?.[formik.values.parentLedger]?.parentGroup || 'None'}
                      </option>
                    </select>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>
                    <span className='required'>Sub Group</span>
                  </label>

                  <div className='col-lg-9 fv-row'>
                    <select
                      className='form-select form-select-disabled form-select-lg'
                      value={formik.values.parentLedger}
                      disabled={true}
                    >
                      <option value={formik.values.parentLedger}>
                        {ledgerMapping?.[formik.values.parentLedger]?.subGroup || 'None'}
                      </option>
                    </select>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>
                    <span className='required'>Parent Group</span>
                  </label>

                  <div className='col-lg-9 fv-row'>
                    <select
                      className='form-select form-select-solid form-select-lg '
                      {...formik.getFieldProps('parentGroup')}
                    >
                      <option value=''>Select a Parent Group...</option>
                      {groupMapping &&
                        Object.keys(groupMapping).map((group) => (
                          <option key={group} value={group}>
                            {group}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>
                    <span className='required'>Sub Group</span>
                  </label>

                  <div className='col-lg-9 fv-row'>
                    <select
                      className='form-select form-select-solid form-select-lg'
                      {...formik.getFieldProps('subGroup')}
                    >
                      <option value=''>Select a Sub Group...</option>
                      {groupMapping &&
                        groupMapping[formik.values.parentGroup]?.map((subGroup: string) => (
                          <option value={subGroup}>{subGroup}</option>
                        ))}
                    </select>
                  </div>
                </div>
              </>
            )}

            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className='required'>Ledger Code</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Ledger Code'
                  {...formik.getFieldProps('ledgerCode')}
                />
                {formik.touched.ledgerCode && formik.errors.ledgerCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.ledgerCode}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className='required'>Opening Balance</span>
              </label>

              <div className='col-lg-3 fv-row'>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Opening Balance'
                  {...formik.getFieldProps('openingBalance')}
                />
                {formik.touched.openingBalance && formik.errors.openingBalance && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.openingBalance}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-3'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  value={currency}
                  onChange={(e) => setCurrency(e.currentTarget.value)}
                >
                  <option value=''>Select a Currency...</option>
                  {currencyOptions.map((c, i) => (
                    <option key={i} value={c.currency_code}>
                      {c.currency_code}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className='required'>As on start of</span>
              </label>
              <div className='col-lg-6 fv-row'>
                <Select
                  value={financialYear}
                  onChange={(option) => setFinancialYear(option as OptionType)}
                  isDisabled={!company?.value}
                  options={[financialYear]}
                  isSearchable={false}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      marginLeft: '2px',
                      // width: '150px',
                      justifyContent: 'center',
                    }),
                  }}
                  components={{IndicatorSeparator: () => null}}
                />
                {/* <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Ledger Code'
                  {...formik.getFieldProps('ledgerCode')}
                />
                {formik.touched.ledgerCode && formik.errors.ledgerCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.ledgerCode}</div>
                  </div>
                )} */}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className='required'>Balance Type</span>
              </label>
              <div className='col-lg-9 fv-row d-flex'>
                <span className='form-check form-check-custom form-check-solid fs-6'>
                  Credit{' '}
                  <input
                    className='form-check-input'
                    type='radio'
                    name='credit'
                    value='credit'
                    checked={formik.values.balanceType === 'credit'}
                    onChange={() => formik.setFieldValue('balanceType', 'credit')}
                    style={{marginLeft: '10px'}}
                  />
                </span>
                <span
                  style={{marginLeft: '20px'}}
                  className='form-check form-check-custom form-check-solid ml-2 fs-6'
                >
                  Debit{' '}
                  <input
                    className='form-check-input ml-2'
                    type='radio'
                    name='debit'
                    value='debit'
                    checked={formik.values.balanceType === 'debit'}
                    onChange={() => formik.setFieldValue('balanceType', 'debit')}
                    style={{marginLeft: '10px'}}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className='p-8 col pt-0 pt-md-8 pb-md-4'>
            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className=''>Email</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  type='email'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Email'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className=''>Person Name</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Person Name'
                  {...formik.getFieldProps('personName')}
                />
                {formik.touched.personName && formik.errors.personName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.personName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className=''>Address</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  type='textarea'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Address'
                  {...formik.getFieldProps('address')}
                />
                {formik.touched.address && formik.errors.address && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.address}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className=''>State</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='State'
                  {...formik.getFieldProps('state')}
                />
                {formik.touched.state && formik.errors.state && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.state}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className=''>Country</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Country'
                  {...formik.getFieldProps('country')}
                />
                {formik.touched.country && formik.errors.country && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.country}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className=''>Zip Code</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Zip Code'
                  {...formik.getFieldProps('zipCode')}
                />
                {formik.touched.zipCode && formik.errors.zipCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.zipCode}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className=''>Bank Account Number</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Bank Account Number'
                  {...formik.getFieldProps('bankAccountNumber')}
                />
                {formik.touched.bankAccountNumber && formik.errors.bankAccountNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.bankAccountNumber}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className=''>Bank Branch Name</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Bank Branch Name'
                  {...formik.getFieldProps('bankBranchName')}
                />
                {formik.touched.bankBranchName && formik.errors.bankBranchName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.bankBranchName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className=''>Bank IFSC Code</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Bank IFSC Code'
                  {...formik.getFieldProps('bankIFSCCode')}
                />
                {formik.touched.bankIFSCCode && formik.errors.bankIFSCCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.bankIFSCCode}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end px-9 border-0 pb-7 pt-0'>
          <button
            className='btn btn-secondary'
            style={{marginRight: '10px'}}
            onClick={() => setCreateLedger(false)}
          >
            Cancel
          </button>
          <button
            type='submit'
            className='btn btn-primary ml-2'
            disabled={loading || !formik.isValid}
          >
            {!loading && !!editLedger ? 'Save Ledger' : 'Add Ledger'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}
