// @ts-nocheck
import {Column} from 'react-table'
import {Modal} from 'react-bootstrap'
import {toast} from 'react-hot-toast'
import {UserCustomHeader} from '../../apps/user-management/users-list/table/columns/UserCustomHeader'
import {KTIcon, formatNumber} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import {Transaction, deleteTransaction, getTransactionDetails} from '../API/ViewTransactionsAPI'
import {useLayout} from '../../../../_metronic/layout/core'
import {useNavigate} from 'react-router-dom'
import React from 'react'

const getViewTransactionColumns = (
  activeTab: string,
  showDeleteModal: boolean,
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>,
  showViewTransactionModal: boolean,
  setShowViewTransactionModal: React.Dispatch<React.SetStateAction<boolean>>,
  setTransactionData: void
): ReadonlyArray<Column<Transaction>> => {
  const handleView = (transactionData) => {
    setTransactionData(transactionData)
    setShowViewTransactionModal(true)
  }
  return [
    {
      Header: (props) => (
        <UserCustomHeader
          styles={{width: '10%'}}
          tableProps={props}
          title='Voucher Number'
          className='min-w-125px ps-4 text-center'
        />
      ),
      accessor: 'voucherNumber',
      Cell: ({...props}) => (
        <div className='px-3 text-center'>{props.data[props.row.index].voucherNumber} </div>
      ),
    },
    {
      Header: (props) => (
        <UserCustomHeader
          styles={{width: '8%'}}
          tableProps={props}
          title='Voucher Name'
          className='min-w-125px text-center'
        />
      ),
      accessor: 'voucherName',
      Cell: ({...props}) => (
        <div className='text-center'>{props.data[props.row.index].voucherName ?? '-'} </div>
      ),
    },
    {
      Header: (props) => (
        <UserCustomHeader
          styles={{width: '8%'}}
          tableProps={props}
          title='Voucher Date'
          className='min-w-125px ps-4 text-center'
        />
      ),
      accessor: 'voucherDate',
      Cell: ({...props}) => (
        <div className='text-center'>{props.data[props.row.index].voucherDate ?? '-'} </div>
      ),
    },
    {
      Header: (props) => (
        <UserCustomHeader
          styles={{width: '8%'}}
          tableProps={props}
          title='Transacting Currency'
          className='min-w-125px text-center'
        />
      ),
      accessor: 'transactingCurrency',
      Cell: ({...props}) => (
        <div className='text-center'>
          <span
            className={clsx(
              'badge fw-bold my-2',
              `${
                props.data[props.row.index].transactingCurrency ===
                props.data[props.row.index].reportingCurrency
                  ? 'badge-light-primary'
                  : 'badge-light'
              }`
            )}
          >
            {props.data[props.row.index].transactingCurrency}
          </span>
        </div>
      ),
    },
    {
      Header: (props) => (
        <UserCustomHeader
          styles={{width: '30%'}}
          tableProps={props}
          title='Total Amount'
          className='min-w-250px ps-4 text-center'
        />
      ),
      accessor: 'totalAmount',
      Cell: ({...props}) => (
        <div className='text-center'>
          {props.data[props.row.index].reportingCurrency}{' '}
          {formatNumber(props.data[props.row.index].totalAmount)}
          {props.data[props.row.index].reportingCurrency !==
            props.data[props.row.index].transactingCurrency &&
            `  (${props.data[props.row.index].transactingCurrency} ${formatNumber(
              props.data[props.row.index].totalAmount /
                props.data[props.row.index].exchangeMultiplier
            )})`}
        </div>
      ),
    },
    {
      Header: (props) => (
        <UserCustomHeader
          styles={{width: '28%'}}
          tableProps={props}
          title='Narration'
          className='max-w-125px text-center'
        />
      ),
      accessor: 'narration',
      Cell: ({...props}) => (
        <div className='text-center max-w-250px'>
          {!props.data[props.row.index].narration
            ? '-'
            : `${props.data[props.row.index].narration.substring(0, 50)}${
                props.data[props.row.index].narration.length > 50 ? '...' : ''
              }`}{' '}
        </div>
      ),
    },
    {
      Header: (props) => (
        <UserCustomHeader
          styles={{width: '8%'}}
          tableProps={props}
          title='Actions'
          className='min-w-125px'
        />
      ),
      accessor: 'actions',
      Cell: (props) => {
        const navigate = useNavigate()
        const {company, financialYear} = useLayout()

        const handleEdit = async () => {
          const res: any = await getTransactionDetails(
            company.value,
            financialYear.value,
            props.data[props.row.index].voucherType,
            props.data[props.row.index].dbId,
            props.data[props.row.index].voucherNumber
          )
          navigate(`/transactions/enter/${activeTab}`, {
            state: {
              transaction: res,
            },
          })
        }

        return (
          <>
            <div className='d-flex justify-content-start flex-shrink-0'>
              <div
                onClick={() => {
                  handleView(props.data[props.row.index])
                }}
                data-bs-toggle='tooltip'
                data-bs-trigger='hover'
                data-bs-dismiss-='click'
                title='More'
                className={clsx('btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1')}
              >
                <KTIcon iconName='arrow-two-diagonals' className={clsx('fs-3')} />
              </div>
              <div
                onClick={handleEdit}
                data-bs-toggle='tooltip'
                data-bs-trigger='hover'
                data-bs-dismiss-='click'
                title='Edit'
                className={clsx('btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1')}
              >
                <KTIcon iconName='pencil' className={clsx('fs-3')} />
              </div>
              <div
                data-bs-toggle='tooltip'
                data-bs-trigger='hover'
                data-bs-dismiss-='click'
                title='Delete'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                onClick={() => {
                  setShowDeleteModal(true)
                  setTransactionData(props.data[props.row.index])
                }}
              >
                <KTIcon iconName='trash' className='fs-3' />
              </div>
            </div>
          </>
        )
      },
    },
  ]
}

export {getViewTransactionColumns}
