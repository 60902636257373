import React, {FC} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {ErrorMessage, Field} from 'formik'

const Step4: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-gray-900'>Login Details</h2>

        <div className='text-gray-500 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='text-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div>
      </div>

      <div className='d-flex flex-column mb-7 fv-row'>
        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
          <span className='required'>Username</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title="Specify a card holder's name"
          ></i>
        </label>

        <Field type='text' className='form-control form-control-solid' placeholder='' name='name' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='name' />
        </div>
      </div>
      <div className='fv-row mb-7'>
        <label className='form-label fw-bold required'>Email</label>

        <Field name='email' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='email' />
        </div>
      </div>
      <div className='fv-row mb-7'>
        <label className='form-label fw-bold required'>Password</label>

        <Field
          type='password'
          name='password'
          className='form-control form-control-lg form-control-solid'
        />

        <div className='text-danger mt-2'>
          <ErrorMessage name='password' />
        </div>
      </div>
      <div className='fv-row mb-7'>
        <label className='form-label fw-bold required'>Confirm Password</label>

        <Field
          type='password'
          name='confirmPassword'
          className='form-control form-control-lg form-control-solid'
        />

        <div className='text-danger mt-2'>
          <ErrorMessage name='confirmPassword' />
        </div>
      </div>
    </div>
  )
}

export {Step4}
