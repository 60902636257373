import {useLocation, Link} from 'react-router-dom'

const paths = [
  {title: 'Receivables', path: '/transactions/aging-report/receivables'},
  {title: 'Payables', path: '/transactions/aging-report/payables'},
]

export const HeaderTabs: React.FC<{
  setActiveTab: React.Dispatch<React.SetStateAction<'receivables' | 'payables'>>
}> = ({setActiveTab}) => {
  const location = useLocation()

  return (
    <div className='card mb-5'>
      <div className='card-body pt-1 pb-1'>
        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            {paths.map((p, i) => (
              <li className='nav-item' key={p.path + i}>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === p.path && 'active')
                  }
                  onClick={() => setActiveTab(p.path.split('/')[3] as 'receivables' | 'payables')}
                  to={p.path}
                >
                  {p.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
